import { HttpClient } from '@angular/common/http';
import { EventEmitter, inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Client, PrivateSellingWhitelist } from '../models/client.model';

export interface WhitelistData {
  email: string;
  tagId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrivateSellingService {
  private http = inject(HttpClient);

  public privateSellingToggleEvent = new EventEmitter<boolean>();
  public initialWhitelist = signal([]);

  private clientApiRoot = '/bo/clients/private-selling' as const;
  private privateSellingApiRoot = '/bo/private-selling/emails' as const;

  public checkForEmailChanges(
    whitelist: Partial<PrivateSellingWhitelist>[]
  ): boolean {
    let haveDifferentValues = false;

    // Early exit on no length
    if (!whitelist.length) {
      return haveDifferentValues;
    }

    // Early exit on length mismatch, meaning arrays have to have different values for sure
    const initialWhitelist = this.initialWhitelist();
    if (whitelist.length !== initialWhitelist.length) {
      return true;
    }

    // Here arrays are same size, but we still have to check if the emails on both arrays are the same,
    // as they might have been edited, or some array was deleted to later add a different one,
    // which is same size but still they are differnt arrays

    // We create Set for both arrays
    const newEmails = new Set(whitelist.map(a => a.email));
    const initialEmails = new Set(initialWhitelist.map(a => a.email));

    // We could have used Array.includes in only one array but this has O(n) time complexity.
    // So we use some() + has() on both arrays, which has a O(1) complexity.
    // Using Set comparsion starts to be more efficient on arrays of length 10 or more.
    // For our use case, most of the whitelists will have more than this size, so this was the chosen approach.
    haveDifferentValues =
      [...newEmails].some(email => !initialEmails.has(email)) ||
      [...initialEmails].some(email => !newEmails.has(email));

    return haveDifferentValues;
  }

  public enablePrivateSelling(whitelistData: WhitelistData[]) {
    return this.http.patch(environment.apiURl + `${this.clientApiRoot}`, {
      whitelistData,
    });
  }

  public disablePrivateSelling() {
    return this.http.delete(environment.apiURl + `${this.clientApiRoot}`);
  }

  public getClientWithWhitelist() {
    return this.http.get<Client>(
      environment.apiURl +
        `${this.clientApiRoot}?relation=privateSellingWhitelist`
    );
  }

  public updateWhitelist(whitelistData: WhitelistData[]) {
    return this.http.patch<{ message: string; whitelist: WhitelistData[] }>(
      environment.apiURl + `${this.privateSellingApiRoot}`,
      {
        whitelistData,
      }
    );
  }

  public deleteWhitelist() {
    return this.http.delete(
      environment.apiURl + `${this.privateSellingApiRoot}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUserModel } from '../../models/auth-user.model';
import { AuthService } from '../../services/auth.service';
import { EmailTemplateResponse } from '../models/email.model';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  private apiRoot = '/bo/emails';
  private loggedUser: AuthUserModel;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.getUserLogged().subscribe(user => {
      this.loggedUser = user;
    });
  }

  public get$(
    templateName = 'orderConfirmation'
  ): Observable<EmailTemplateResponse> {
    return this.http.get<EmailTemplateResponse>(
      environment.apiURl + `${this.apiRoot}/${templateName}`
    );
  }

  public update$(
    data: EmailTemplateResponse,
    templateName = 'orderConfirmation'
  ): Observable<EmailTemplateResponse> {
    return this.http.patch<EmailTemplateResponse>(
      environment.apiURl + `${this.apiRoot}/${templateName}`,
      data
    );
  }

  public getPreview$(
    templateName = 'orderConfirmation'
  ): Observable<{ template: string }> {
    return this.http.get<{ template: string }>(
      environment.apiURl +
        `${this.apiRoot}/preview/${templateName}?patronName=${this.loggedUser.username}`
    );
  }

  public sendDemoEmail$(
    templateName = 'orderConfirmation'
  ): Observable<{ email: string }> {
    return this.http.post<{ email: string }>(
      environment.apiURl + `${this.apiRoot}/send/test`,
      {
        templateName,
        sendTo: this.loggedUser.email,
        patronName: this.loggedUser.username,
      }
    );
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pagination } from './models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy {
  public pagination: Pagination;
  private subscriptions: Array<Subscription>;
  @Input() totalItemsInDb: number;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  @Output() changepage = new EventEmitter<number>();

  constructor(
    private routeService: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.setPagination(this.currentPage);
  }

  ngOnDestroy(): void {
    console.log('Destroy');
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public refresh(page, totalItemsInDb, itemsPerPage): void {
    this.totalItemsInDb = totalItemsInDb;
    this.itemsPerPage = itemsPerPage;
    this.setPagination(page);
  }

  public setPagination(page_param?: number) {
    let current = 1;

    if (page_param) {
      current = parseInt(page_param.toString());
    }

    let lastPage = Math.ceil(this.totalItemsInDb / this.itemsPerPage);
    let next_page = current + 1;
    let previous_page = current - 1;
    if (!previous_page) {
      previous_page = 1;
    }

    if (previous_page < 0) previous_page = 1;

    if (lastPage === current) {
      next_page = lastPage;
    }
    let min_pages_to_quick = 2;
    this.pagination = {
      current: current,
      next: next_page,
      previous: previous_page,
      last: lastPage,
      quickpages: {
        previous: Array(min_pages_to_quick)
          .fill(0)
          .map((x, i) => {
            let pages_toadd = current - (i + 1);
            if (pages_toadd >= 1) {
              return pages_toadd;
            }
            return null;
          })
          .sort(),
        next: Array(min_pages_to_quick)
          .fill(0)
          .map((x, i) => {
            let pages_toadd = current + (i + 1);
            if (pages_toadd >= lastPage) {
              pages_toadd = lastPage;
            }
            return pages_toadd;
          }),
      },
    };
    if (this.pagination.quickpages.previous.includes(null)) {
      if (this.pagination.current > 1) {
        this.pagination.quickpages.previous = [1];
      } else {
        this.pagination.quickpages.previous = [];
      }
    }
    if (this.pagination.quickpages.next.includes(lastPage)) {
      if (this.pagination.current === this.pagination.last - 1) {
        this.pagination.quickpages.next = [this.pagination.last];
      } else if (this.pagination.current === this.pagination.last) {
        this.pagination.quickpages.next = [];
      }
    }

    let n_previous = this.pagination.quickpages.previous.length;
    let n_next = this.pagination.quickpages.next.length;
    if (n_previous < n_next) {
      let diff = n_next - n_previous;
      if (diff > min_pages_to_quick) {
        for (let i = 0; i < diff; i++) {
          let last_next =
            this.pagination.quickpages.next[
              this.pagination.quickpages.next.length - 1
            ];
          this.pagination.quickpages.next.push(last_next + 1);
        }
      }
    }
    if (n_next < n_previous) {
      let diff = n_previous - current;
      if (diff > 1) {
        for (let i = 0; i < diff; i++) {
          let last_prev =
            this.pagination.quickpages.previous[
              this.pagination.quickpages.previous.length - 1
            ];
          this.pagination.quickpages.previous.push(last_prev - 1);
        }
      }
      this.pagination.quickpages.previous.sort();
    }
  }

  public goto(page: number) {
    if (this.pagination.current === 1) {
      if (page === 1) return;
    }
    if (this.pagination.current === this.pagination.last) {
      if (page === this.pagination.last) return;
    }

    let currenturl =
      '/private/' + this.activatedRoute.snapshot.routeConfig.path;
    let queryParams = JSON.parse(
      JSON.stringify(this.activatedRoute.snapshot.queryParams)
    );
    queryParams['page'] = page;
    this.routeService.navigate([currenturl], { queryParams: queryParams });
    this.setPagination(Number(page));
    this.changepage.emit(this.pagination.current);
  }
}

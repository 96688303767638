<div class="content-header">
  <div>
    <h1>AdLink Management</h1>
  </div>
  <div>
    <button type="button" class="default-1" (click)="openModalCreate()">
      Create New AdLink <i class="ti ti-plus"></i>
    </button>
  </div>
</div>
<div class="default-table v2">
  <div class="loading" *ngIf="refreshing">
    <div class="text-container">
      <div>
        <span>Loading Adlinks</span>
      </div>
      <div>
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Reference</th>
        <th>Title</th>
        <th>Link</th>
        <th>Visibility</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of data">
        <td>
          <img [src]="i.url_image" />
        </td>
        <td>
          <b>{{ i.name }}</b>
        </td>
        <td>
          <b>{{ i.title }}</b>
        </td>
        <td>
          <div *ngIf="i.link">
            <a [href]="i.link" class="text-decoration-none" target="_blank">
              <i class="ti ti-link"></i>
            </a>
          </div>
        </td>
        <td class="visibility-wrapper">
          <div>
            <div *ngIf="i.active; else offvisibility">
              <button class="default-2" (click)="toggleActive(i)">
                <i class="ti ti-eye"></i>On
              </button>
            </div>
            <ng-template #offvisibility>
              <div>
                <button class="default-2 off" (click)="toggleActive(i)">
                  <i class="ti ti-eye"></i>Off
                </button>
              </div>
            </ng-template>
            <div>
              <button class="default-2 red" (click)="openModalDelete(i.id)">
                Delete
              </button>
            </div>
          </div>
        </td>
        <td>
          <button class="default-3" (click)="openModalEdit(i)">
            <i class="ti ti-pencil"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="legend">
    Showing {{ data.length }} of {{ total_items_in_db }} total Events
  </p>
</div>

<app-pagination
  [currentPage]="current_page"
  [total_items_in_db]="total_items_in_db"
  [items_per_page]="items_per_page"
  #pagination></app-pagination>

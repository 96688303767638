import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as appPackage from '../../../../../package.json';
import { SidebarStatus } from '../../models/sidebar.model';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() sidebarStatus: SidebarStatus;
  @Output() sidebarStatusEvent = new EventEmitter<SidebarStatus>();
  public active: string;
  public appPackage = appPackage;
  private subscriptions: Array<Subscription>;

  constructor(
    private routeService: Router,
    public configurationService: ConfigurationService,
    public readonly authService: AuthService
  ) {
    this.active = this.routeService.url.split('?')[0].split('/')[2];
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const o = this.routeService.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.active = val.url.split('?')[0].split('/')[2];
      }
    });
    this.subscriptions.push(o);
  }

  public toggleSidebar() {
    this.sidebarStatus.open = !this.sidebarStatus.open;
    this.sidebarStatusEvent.emit(this.sidebarStatus);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => {
      o.unsubscribe();
    });
  }
}

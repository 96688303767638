import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CheckedEvents } from 'src/app/private/layout/modal-event-selector/models/checkedevents.model';
import { UtilsService } from '../../../services/utils.service';
import { Upgrade } from '../../models/upgrade.model';
import { UpgradeService } from '../../services/upgrade.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent {
  public item: Upgrade;
  public postImage: File;
  public imagePreview: any;
  private closeReason: 'success' | 'cancel';
  private checked_events: CheckedEvents;
  public hashValidator = {
    name: false,
    description: false,
    buyerTypeName: false,
    buyerTypeId: false,
    price: false,
  };

  constructor(
    private bsModalRef: BsModalRef,
    private upgradeService: UpgradeService,
    private utils: UtilsService
  ) {
    this.item = {
      active: true,
      name: '',
      description: '',
      url_image: '',
      buyerTypeId: '',
      buyerTypeName: '',
      price: 0,
    };
    this.imagePreview = null;
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onFileChange(event) {
    this.postImage = event.target.files[0];
    var reader = new FileReader();
    this.imagePreview = null;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.imagePreview = reader.result;
    };
  }

  public onConfirm() {
    let hasError = this.checkInputs(this.item);
    if (hasError) return;
    this.upgradeService
      .create$(this.item, this.postImage, this.checked_events.selected.ids)
      .subscribe({
        next: (response: Upgrade) => {
          this.onDismiss('success');
        },
        error: err => {
          console.log(err);
        },
      });
  }

  // check specified fields and change value at hasValidator
  // help to detect wich inputs have bad values
  private checkInputs(instance: Upgrade): boolean {
    let hasErrors = false;
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach(value => {
      this.hashValidator[value] = false;
    });
    if (this.utils.isEmpty(instance.buyerTypeName)) {
      this.hashValidator.buyerTypeName = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.description)) {
      this.hashValidator.description = true;
      hasErrors = true;
    }
    if (!this.utils.onlyHasNumbers(instance.buyerTypeId)) {
      this.hashValidator.buyerTypeId = true;
      hasErrors = true;
    }
    if (instance.price <= 0) {
      this.hashValidator.price = true;
      hasErrors = true;
    }

    return hasErrors;
  }

  public getCheckedEvents($event) {
    this.checked_events = $event;
  }
}

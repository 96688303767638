import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';
import { Event, QueryParams } from '../models/event.model';

export type BulkEditAction = 'disable' | 'enable' | 'delete';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private apiRoot = '/bo/events' as const;

  private _selectedEventsIds = new BehaviorSubject<number[]>([]);
  public get selectedEventsIds() {
    return this._selectedEventsIds.asObservable();
  }

  public getSelectedEventIds(): number[] {
    return this._selectedEventsIds.getValue();
  }

  public eventDeletedEvent = new EventEmitter<number>();
  public eventBulkEditEvent = new EventEmitter<BulkEditAction>();
  public eventBulkEditConfirmEvent = new EventEmitter<BulkEditAction>();

  constructor(private http: HttpClient) {}

  public getAll$(
    page?: number,
    queryParams?: QueryParams
  ): Observable<ApiResponse<Event[]>> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }
    if (queryParams?.future) {
      const year = new Date().getFullYear();
      const month = String(new Date().getMonth() + 1).padStart(2, '0');
      const day = String(new Date().getDate()).padStart(2, '0');
      const todayDateParam = `${year}-${month}-${day}`;

      query += `&filters[date][$gte]=${todayDateParam}`;
    }

    return this.http.get<ApiResponse<Event[]>>(
      environment.apiURl + `${this.apiRoot}${query}`
    );
  }

  public getAllPageLess$(): Observable<ApiResponse<Event[]>> {
    let query = '?page=1&perPage=9999';
    return this.http.get<ApiResponse<Event[]>>(
      environment.apiURl + `${this.apiRoot}${query}`
    );
  }

  public refresh$(): Observable<ApiResponse<null>> {
    return this.http.get<ApiResponse<null>>(
      environment.apiURl + `${this.apiRoot}/import`
    );
  }

  public get$(id: number): Observable<ApiResponse<Event>> {
    return this.http.get<ApiResponse<Event>>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public searchEvent$(id: string): Observable<ApiResponse<Event[]>> {
    const params = new HttpParams().set('filters[code][$contains]', id);

    return this.http.get<ApiResponse<Event[]>>(
      environment.apiURl + `${this.apiRoot}/`,
      { params }
    );
  }

  public update$(
    id: number,
    nmEntity: string,
    nm: Array<string | number>
  ): Observable<ApiResponse<Event>> {
    const formData: FormData = new FormData();

    if (nm) {
      if (nm.length) {
        nm.forEach(i => {
          formData.append(nmEntity + '[]', i.toString());
        });
      } else {
        formData.append(nmEntity, '[]');
      }
    }

    return this.http.patch<ApiResponse<Event>>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      formData
    );
  }

  public updateAdlink$(
    id: number,
    adlink: number
  ): Observable<ApiResponse<Event>> {
    const formData: FormData = new FormData();
    formData.append('adlinkId', String(adlink));

    return this.http.patch<ApiResponse<Event>>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      formData
    );
  }

  public updateDetails$(
    id: number,
    details: Partial<Event>,
    file?: File
  ): Observable<ApiResponse<Event>> {
    const formData = new FormData();

    if (file) {
      formData.append('image', file, file.name);
    }
    if (typeof details.extraText === 'string') {
      formData.append('extraText', details.extraText);
    }
    if (details.defaultBuyerType) {
      formData.append('defaultBuyerType', String(details.defaultBuyerType));
    }
    if (details.defaultBuyerTypeName) {
      formData.append('defaultBuyerTypeName', details.defaultBuyerTypeName);
    }
    if (details.urlImage == null) {
      formData.append('urlImage', 'null');
    } else {
      const endIndex =
        details.urlImage.indexOf('?') > -1
          ? details.urlImage.indexOf('?')
          : Infinity;
      formData.append('urlImage', details.urlImage.substring(0, endIndex));
    }

    return this.http.patch<ApiResponse<Event>>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      formData
    );
  }

  public addSelectedEvent(id: number) {
    const currentSelected = this._selectedEventsIds.getValue();
    if (!currentSelected.includes(id)) {
      this._selectedEventsIds.next([...currentSelected, id]);
    }
  }

  public removeSelectedEvent(id: number) {
    const currentSelected = this._selectedEventsIds.getValue();
    this._selectedEventsIds.next(
      currentSelected.filter(selectedId => selectedId !== id)
    );
  }

  public removeEventOnDb(
    id: number
  ): Observable<ApiResponse<Pick<Event, 'id'>>> {
    return this.http.delete<ApiResponse<Pick<Event, 'id'>>>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public toggleEnableOnDb(
    event: Pick<Event, 'id' | 'isEnabled'>
  ): Observable<ApiResponse<Event>> {
    const formData = new FormData();
    formData.append('isEnabled', String(event.isEnabled));

    return this.http.patch<ApiResponse<Event>>(
      environment.apiURl + `${this.apiRoot}/${event.id}`,
      formData
    );
  }

  public selectAllEvents(events: Event[]) {
    const eventIds = events.map(event => event.id);
    this._selectedEventsIds.next([]);
    this._selectedEventsIds.next([...eventIds]);
  }

  public unSelectAllEvents() {
    this._selectedEventsIds.next([]);
  }

  public editBulk(
    action: Exclude<BulkEditAction, 'delete'>
  ): Observable<ApiResponse<Event[]>> {
    const selectedEventsIds = this.getSelectedEventIds();

    const events = selectedEventsIds.map(id => ({
      id,
      isEnabled: action === 'enable',
    }));

    return this.http.patch<ApiResponse<Event[]>>(
      environment.apiURl + `${this.apiRoot}/bulk`,
      { events }
    );
  }

  public deleteBulk(): Observable<ApiResponse<null>> {
    const selectedEventsIds = this.getSelectedEventIds();
    const params = new HttpParams();

    selectedEventsIds.forEach(id => {
      params.append('id', id.toString());
    });

    return this.http.delete<ApiResponse<null>>(
      environment.apiURl + `${this.apiRoot}/bulk`,
      { params }
    );
  }
}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { provideNgVibeDrawer } from '@ng-vibe/drawer';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FileSaverModule } from 'ngx-filesaver';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { HttpBearerInterceptor } from './private/interceptors/bearer-token.interceptor';
import { EnvironmentInterceptor } from './private/interceptors/environment.interceptor';
import { PrivateComponent } from './private/private.component';
import { PrivateModule } from './private/private.module';
import { ConfigurationService } from './private/services/configuration.service';
import { TemplatePageTitleStrategyService } from './private/services/template-page-title-strategy.service';

/**
 * Initializes application with certain parameters fetched from the URL and backend.
 * It's intended to run before the application fully bootstraps.
 * @param http - The HTTP client used for making the request.
 * @returns A function that returns a promise, signaling the app initialization completion.
 */
function initializeApp(configurationService: ConfigurationService): () => void {
  return () => configurationService.init();
}

const appInitObject = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [ConfigurationService],
  multi: true,
};

const bearerInterceptorObject = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpBearerInterceptor,
  multi: true,
};

const environmentInterceptorObject = {
  provide: HTTP_INTERCEPTORS,
  useClass: EnvironmentInterceptor,
  multi: true,
};

const titleStrategy = {
  provide: TitleStrategy,
  useClass: TemplatePageTitleStrategyService,
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent, PrivateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FileSaverModule,
    FormsModule,
    HttpClientModule,
    PrivateModule,
    AuthModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    appInitObject,
    bearerInterceptorObject,
    environmentInterceptorObject,
    titleStrategy,
    provideNgVibeDrawer(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="title">
  Select events
  <span
    ><span class="blue">{{ checked_events.selected.total }}</span> /
    {{ eventList.meta.total }}
  </span>
</div>
<div *ngIf="eventList.data.length" class="input-check-lines-wrapper">
  <div
    class="input-check-line"
    *ngFor="let item of eventList.data"
    (click)="check(item.id)">
    <input type="checkbox" [(ngModel)]="checked_events.list[item.id]" />
    <span
      >{{ item.description }} <br />
      {{ item.code }} - {{ item.date | date }}</span
    >
  </div>
</div>

<div class="pager">
  <button
    class="btn-page"
    type="button"
    [ngClass]="{ disabled: eventList.meta.current_page - 1 <= 0 }"
    (click)="goto(eventList.meta.current_page - 1)">
    <i class="ti ti-angle-left"></i>
  </button>
  <div class="current">
    <span
      >{{ eventList.meta.current_page }}/{{ eventList.meta.last_page }}</span
    >
  </div>
  <button
    class="btn-page"
    type="button"
    [ngClass]="{
      disabled: eventList.meta.current_page + 1 > eventList.meta.last_page
    }"
    (click)="goto(eventList.meta.current_page + 1)">
    <i class="ti ti-angle-right"></i>
  </button>
</div>

<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>Are you sure you want to delete this event?</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper">
      <div>
        <div class="input-line">
          <div>
            <span><b>Code</b></span>
          </div>
          <div>
            <p>{{ data?.code }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Description</b></span>
          </div>
          <div>
            <p>{{ data?.description }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Date</b></span>
          </div>
          <div>
            <p>{{ data?.date | date }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-4" (click)="onDismiss()">Cancel</button>
      <button class="default-1" (click)="onConfirm(data.id)">
        Permanently delete
      </button>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';
import { RedisQuery, RedisRecord } from '../models/redis-manager.model';

export type RedisGetAllResult = {
  data: RedisRecord[];
  totalCount: number;
  nextCursor: string;
};

@Injectable({
  providedIn: 'root',
})
export class RedisManagerService {
  private apiRoot = '/bo/redis';

  constructor(private http: HttpClient) {}

  public delete$(key: string): Observable<ApiResponse<RedisRecord>> {
    return this.http.delete<ApiResponse<RedisRecord>>(
      environment.apiURl + `${this.apiRoot}/${key}`
    );
  }

  public deleteAll$(): Observable<ApiResponse<{ value: 'OK' }>> {
    return this.http.delete<ApiResponse<{ value: 'OK' }>>(
      environment.apiURl + `${this.apiRoot}`
    );
  }

  public deleteKeys$(keys: string[]): Observable<
    ApiResponse<{
      keys: string[];
      value: number;
    }>
  > {
    return this.http.delete<
      ApiResponse<{
        keys: string[];
        value: number;
      }>
    >(environment.apiURl + `${this.apiRoot}/keys`, {
      body: {
        keys,
      },
    });
  }

  public get$(key: string): Observable<ApiResponse<RedisRecord>> {
    return this.http.get<ApiResponse<RedisRecord>>(
      environment.apiURl + `${this.apiRoot}/${key}`
    );
  }

  public getAll$({
    cursor = '0',
    limit = 25,
    search = '',
  }: RedisQuery = {}): Observable<ApiResponse<RedisGetAllResult>> {
    return this.http.get<ApiResponse<RedisGetAllResult>>(
      environment.apiURl + `${this.apiRoot}`,
      {
        params: {
          cursor,
          limit: limit.toString(),
          search,
        },
      }
    );
  }
}

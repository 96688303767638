import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { ErrorDetailComponent } from './modals/error-detail/error-detail.component';
import { ViewComponent } from './modals/view/view.component';
import { TransactionsComponent } from './transactions.component';

@NgModule({
  declarations: [TransactionsComponent, ViewComponent, ErrorDetailComponent],
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [TransactionsComponent],
})
export class TransactionsModule {}

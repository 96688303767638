<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <button
        type="button"
        class="btn-modal-header"
        (click)="closeDrawer()"
        data-dismiss="modal">
        <span class="ti ti-angle-double-right"></span>
      </button>
      <p>Error Details</p>
    </div>

    <div class="json-container">
      <pre>{{ errorJson }}</pre>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RestorePasswordComponent } from './auth/restore-password/restore-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdlinkComponent } from './private/adlink/adlink.component';
import { adLinkResolver } from './private/adlink/resolvers/adlink.resolver';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { dashboardResolver } from './private/dashboard/resolvers/dashboard.resolver';
import { EmailsComponent } from './private/emails/emails.component';
import { emailTemplateDataResolver } from './private/emails/resolvers/emails.resolver';
import { EventsComponent } from './private/events/events.component';
import { eventsResolver } from './private/events/resolvers/events.resolver';
import { PrivateComponent } from './private/private.component';
import { AuthGuard } from './private/private.guard';
import { RedisManagerComponent } from './private/redis-manager/redis-manager.component';
import { redisManagerResolver } from './private/redis-manager/resolvers/redis-manager.resolver';
import {
  eventsPageLessResolver,
  transactionsResolver,
} from './private/transactions/resolvers/transactions.resolver';
import { TransactionsComponent } from './private/transactions/transactions.component';
import { upgradeResolver } from './private/upgrade/resolvers/upgrade.resolver';
import { UpgradeComponent } from './private/upgrade/upgrade.component';
import { upsellResolver } from './private/upsell/resolvers/upsell.resolver';
import { UpsellComponent } from './private/upsell/upsell.component';
import { usersResolver } from './private/users/resolvers/users.resolver';
import { UsersComponent } from './private/users/users.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/private/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'private',
    component: PrivateComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'adlink',
        component: AdlinkComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: adLinkResolver,
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          api: dashboardResolver,
          events: eventsPageLessResolver,
        },
      },
      {
        path: 'emails',
        component: EmailsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          emailTemplate: emailTemplateDataResolver,
        },
      },
      {
        path: 'events',
        component: EventsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: eventsResolver,
        },
      },
      {
        path: 'redis-manager',
        component: RedisManagerComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: redisManagerResolver,
        },
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: transactionsResolver,
          events: eventsPageLessResolver,
        },
      },
      {
        path: 'upgrade',
        component: UpgradeComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: upgradeResolver,
        },
      },
      {
        path: 'upsell',
        component: UpsellComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: upsellResolver,
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: usersResolver,
        },
      },
    ],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'restore-password',
    component: RestorePasswordComponent,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

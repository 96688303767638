import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Event, QueryParams } from '../models/event.model';

export type BulkEditAction = 'disable' | 'enable' | 'delete';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private apiRoot = '/bo/events' as const;

  private _selectedEventsIds = new BehaviorSubject<number[]>([]);
  public get selectedEventsIds() {
    return this._selectedEventsIds.asObservable();
  }

  public getSelectedEventIds(): number[] {
    return this._selectedEventsIds.getValue();
  }

  public eventDeletedEvent = new EventEmitter<number>();
  public eventBulkEditEvent = new EventEmitter<BulkEditAction>();
  public eventBulkEditConfirmEvent = new EventEmitter<BulkEditAction>();

  constructor(private http: HttpClient) {}

  public getAll$(page?: number, queryParams?: QueryParams): Observable<any> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }
    if (queryParams) {
      if (queryParams.future) query += '&future=1';
    }
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}${query}`);
  }
  public getAllPageLess$(): Observable<any> {
    let query = '?page=1&limit=9999';
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}${query}`);
  }
  public refresh$(): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/import`);
  }
  public get$(id: number): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }
  public searchEvent$(id: string): Observable<any> {
    let params = new HttpParams().set('search', id);
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/`, {
      params,
    });
  }
  public update$(
    id: number,
    nmEntity: string,
    nm: Array<string | number>
  ): Observable<any> {
    let formData: FormData = new FormData();
    if (nm) {
      if (nm.length) {
        nm.forEach(i => {
          formData.append(nmEntity, i.toString());
        });
      } else {
        formData.append(nmEntity, '');
      }
    }
    return this.http.patch<any>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      formData
    );
  }

  public updateAdlink$(id: number, adlink: number): Observable<any> {
    return this.http.patch<Event>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      { adlinkId: adlink }
    );
  }

  public updateDetails$(id: number, details: any, file?: File) {
    const body = {
      defaultBuyerType: details.defaultBuyerType,
      defaultBuyerTypeName: details.defaultBuyerTypeName,
    };
    let formData = new FormData();
    let options = {};
    if (file) {
      formData.append('image', file, file.name);
    }

    if (details.extraText) {
      formData.append('extraText', details.extraText);
    }
    if (details.defaultBuyerType) {
      formData.append('defaultBuyerType', details.defaultBuyerType);
    }
    if (details.defaultBuyerTypeName) {
      formData.append('defaultBuyerTypeName', details.defaultBuyerTypeName);
    }
    if (details.urlImage == null) {
      formData.append('urlImage', '');
    } else {
      formData.append('urlImage', details.urlImage);
    }

    return this.http.patch<any>(
      environment.apiURl + `${this.apiRoot}/${id}`,
      formData,
      options
    );
  }

  public addSelectedEvent(id: number) {
    const currentSelected = this._selectedEventsIds.getValue();
    if (!currentSelected.includes(id)) {
      this._selectedEventsIds.next([...currentSelected, id]);
    }
  }

  public removeSelectedEvent(id: number) {
    const currentSelected = this._selectedEventsIds.getValue();
    this._selectedEventsIds.next(
      currentSelected.filter(selectedId => selectedId !== id)
    );
  }

  public removeEventOnDb(id: number) {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }

  public toggleEnableOnDb(event: Event) {
    return this.http.patch<any>(
      environment.apiURl + `${this.apiRoot}/${event.id}`,
      {
        ...event,
      }
    );
  }

  public selectAllEvents(events: Event[]) {
    const eventIds = events.map(event => event.id);
    this._selectedEventsIds.next([]);
    this._selectedEventsIds.next([...eventIds]);
  }

  public unSelectAllEvents() {
    this._selectedEventsIds.next([]);
  }

  public editBulk(action: Exclude<BulkEditAction, 'delete'>, events: Event[]) {
    const selectedEventsIds = this.getSelectedEventIds();
    const selectedEvents = events.filter(event =>
      selectedEventsIds.includes(event.id)
    );

    if (action === 'disable') {
      for (const event of selectedEvents) {
        event.isEnabled = false;
      }
    } else {
      for (const event of selectedEvents) {
        event.isEnabled = true;
      }
    }

    return this.http.patch<any>(environment.apiURl + `${this.apiRoot}/batch`, {
      events: selectedEvents,
    });
  }
  public deleteBulk() {
    const selectedEventsIds = this.getSelectedEventIds();
    let params = new HttpParams();
    selectedEventsIds.forEach(id => {
      params = params.append('id', id.toString());
    });

    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/batch`, {
      params,
    });
  }
}

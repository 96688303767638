<div class="page" [ngClass]="{ 'sidebar-open': sidebarStatus.open }">
  <div class="sidebar">
    <app-sidebar
      [sidebarStatus]="sidebarStatus"
      (sidebarStatusEvent)="sidebarStatusEvent($event)"></app-sidebar>
  </div>
  <div class="page-content">
    <div class="header">
      <app-header></app-header>
    </div>
    <div class="page-component">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

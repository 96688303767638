import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BulkEditAction, EventsService } from '../../services/events.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-edit-bulk-confirm',
  templateUrl: './edit-bulk-confirm.component.html',
  styleUrls: ['./edit-bulk-confirm.component.scss'],
})
export class EditBulkConfirmComponent {
  protected action: BulkEditAction;

  constructor(
    private bsModalRef: BsModalRef,
    private eventsService: EventsService
  ) {}

  public onConfirm() {
    this.eventsService.eventBulkEditConfirmEvent.emit(this.action);
  }

  public onDismiss = () => {
    this.bsModalRef.hide();
  };
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransactionError } from '../../models/transaction.model';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-error-detail',
  templateUrl: './error-detail.component.html',
  styleUrls: ['./error-detail.component.scss'],
})
export class ErrorDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription>;
  public data: Array<TransactionError>;

  constructor(private drawerService: DrawerService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.data = this.drawerService.getPayload();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  get errorJson() {
    if (!this.data) {
      return 'There is no error details';
    }

    return JSON.stringify(this.data, null, 4);
  }

  closeDrawer() {
    this.drawerService.closeDrawer();
  }
}

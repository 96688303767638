import { z } from 'zod';
import { Entity } from '../models/configuration.model';

export const configurationSchema = z.object({
  clientName: z.string(),
  appName: z.string(),
  logo: z.string(),
  favicon: z.string(),
  analytics: z.string(),
  theme: z.object({
    'main-color': z.string(),
    'secondary-color': z.string(),
    red: z.string(),
  }),
});

export const configurationData: Entity = {
  default: {
    clientName: 'Local',
    appName: 'Local',
    favicon: '/assets/icons/favicon.png',
    logo: '/assets/img/logo.svg',
    phone: '733-404-4242',
    email: 'cubsgrouptickets@cubs.com',
    analytics: '',
    theme: {
      'main-color': '77 139 219',
      'secondary-color': '39 46 64',
      red: '225 56 63',
    },
  },
  chicagocubs: {
    clientName: 'Chicago Cubs',
    appName: 'Chicago Cubs',
    favicon: '/assets/img/chicagocubs/favicon.png',
    logo: '/assets/img/chicagocubs/logo.svg',
    phone: '733-404-4242',
    email: 'cubsgrouptickets@cubs.com',
    analytics: '',
    theme: {
      'main-color': '77 139 219',
      'secondary-color': '39 46 64',
      red: '225 56 63',
    },
  },
  redsox: {
    clientName: 'Boston Red Sox',
    appName: 'Boston Red Sox',
    favicon: '/assets/img/redsox/favicon.png',
    logo: '/assets/img/redsox/logo.png',
    phone: '000-000-0000',
    email: 'none@3ddgitialvenue.com',
    analytics: '',
    theme: {
      'main-color': '13 43 86',
      'secondary-color': '189 48 57',
      red: '225 56 63',
    },
  },
  houstonastros: {
    clientName: 'Houston Astros',
    appName: 'Houston Astros',
    favicon: '/assets/img/houstonastros/favicon.png',
    logo: '/assets/img/houstonastros/logo.png',
    phone: '000-000-0000',
    email: 'tickethelp@astros.com',
    analytics: '',
    theme: {
      'main-color': '00 44 97',
      'secondary-color': '235 132 31',
      red: '225 56 63',
    },
  },
  pittsburghpirates: {
    clientName: 'Pittsburgh Pirates',
    appName: 'Pittsburgh Pirates',
    favicon: '/assets/img/pittsburghpirates/favicon.png',
    logo: '/assets/img/pittsburghpirates/logo.png',
    phone: '000-000-0000',
    email: 'none@3ddgitialvenue.com',
    analytics: '',
    theme: {
      'main-color': '38 37 31',
      'secondary-color': '253 194 36',
      red: '225 56 63',
    },
  },
  miamimarlins: {
    clientName: 'Miami Marlins',
    appName: 'Miami Marlins',
    favicon: '/assets/img/miamimarlins/favicon.png',
    logo: '/assets/img/miamimarlins/logo.png',
    phone: '000-000-0000',
    email: 'none@3ddgitialvenue.com',
    analytics: '',
    theme: {
      'main-color': '00 163 224',
      'secondary-color': '00 00 00',
      red: '225 56 63',
    },
  },
  chicagocubsspring: {
    clientName: 'Chicago Cubs Spring Training',
    appName: 'Chicago Cubs Spring',
    favicon: '/assets/img/chicagocubs/favicon.png',
    logo: '/assets/img/chicagocubs/logo.svg',
    phone: '733-404-4242',
    email: 'mesa@cubs.com',
    analytics: '',
    theme: {
      'main-color': '23 52 130',
      'secondary-color': '39 46 64',
      red: '225 56 63',
    },
  },
};

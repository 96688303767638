<div class="page">
  <div class="page-content">
    <div class="header">
      <div class="header-wrapper">
        <div class="header-wrapper-col">
          <img
            [src]="configurationService.configuration['logo']"
            alt="club logo" />
          <h1>
            {{ configurationService.configuration['appName'] + ' Group Sales' }}
          </h1>
        </div>
      </div>
    </div>
    <div class="page-component">
      <h1>
        {{
          currentView === viewTypes.login
            ? 'Private Area'
            : 'Reset Your Password'
        }}
      </h1>

      <form #form="ngForm" (ngSubmit)="submit(form)">
        <div class="input-line">
          <div>
            <span>Username</span>
          </div>
          <div>
            <input
              name="username"
              [(ngModel)]="formData.username"
              type="text" />
          </div>
        </div>

        <div class="input-line" *ngIf="currentView === viewTypes.login">
          <div>Password</div>
          <div>
            <label for="user-password" class="password-container">
              <input
                id="user-password"
                name="password"
                [type]="passwordInputType"
                [(ngModel)]="formData.password" />

              <i
                class="ti ti-eye"
                *ngIf="passwordInputType === 'password'"
                (click)="togglePasswordInputType()"></i>
              <i
                class="ti ti-power-off"
                *ngIf="passwordInputType === 'text'"
                (click)="togglePasswordInputType()"></i>
            </label>
          </div>
        </div>

        <p class="view-link" (click)="toggleView()">
          {{ currentView === viewTypes.login ? 'Reset Password' : 'Login' }}
        </p>

        <div
          class="alert"
          [class.alert-danger]="submitResult === submitResultTypes.error"
          [class.alert-success]="submitResult === submitResultTypes.success"
          role="alert"
          *ngIf="!!submitResult && retryInSeconds === 0">
          {{ submitMessage }}
        </div>
        <div
          class="alert alert-warning"
          role="alert"
          *ngIf="retryInSeconds > 0">
          Please wait and try again later : {{ retryInSeconds }}
        </div>

        <button
          type="submit"
          class="default-1"
          *ngIf="currentView === viewTypes.login">
          LOGIN
        </button>
        <button
          type="submit"
          class="default-1"
          *ngIf="currentView === viewTypes.resetPassword"
          [class.disabled]="isLoading || retryInSeconds > 0"
          [disabled]="isLoading || retryInSeconds > 0">
          {{ resetAttempts > 0 ? 'RESEND' : 'RESET PASSWORD' }}
        </button>
      </form>
    </div>
  </div>
</div>

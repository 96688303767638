import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';

type EventTransactionItem = {
  id: number;
  code: string;
  description: string;
  date: string; // '2025-07-22T17:00:00.000-05:00'
  event_transactions: number;
  ca_event_transactions: number;
  ok_event_transactions: number;
  ph_event_transactions: number;
  ok_balance_event_transactions: number | null;
  ph_balance_event_transactions: number | null;
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiRoot = '/bo/diagnostic/';

  constructor(private http: HttpClient) {}

  public getAnalyticsEventsTransactions$(
    limit?: number
  ): Observable<ApiResponse<EventTransactionItem[]>> {
    let query = '';
    let endpoint = 'events/transactions';
    if (limit) {
      query = '?limit=' + limit.toString();
    }
    return this.http.get<ApiResponse<EventTransactionItem[]>>(
      environment.apiURl + `${this.apiRoot}${endpoint}${query}`
    );
  }

  public getAnalyticsEventsTransactionsByCodes$(
    codes: Array<string>
  ): Observable<ApiResponse<EventTransactionItem[]>> {
    let endpoint = 'events/transactions/codes';
    let body = { codes };
    return this.http.post<ApiResponse<EventTransactionItem[]>>(
      environment.apiURl + `${this.apiRoot}${endpoint}`,
      body
    );
  }
}

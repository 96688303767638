import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdlinkModule } from './adlink/adlink.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { EmailsModule } from './emails/emails.module';
import { EventsModule } from './events/events.module';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RedisManagerModule } from './redis-manager/redis-manager.module';
import { TransactionsModule } from './transactions/transactions.module';
import { UpgradeModule } from './upgrade/upgrade.module';
import { UpsellModule } from './upsell/upsell.module';
import { UsersModule } from './users/users.module';
@NgModule({
  declarations: [SidebarComponent, HeaderComponent],
  imports: [
    AdlinkModule,
    CommonModule,
    DashboardModule,
    EmailsModule,
    EventsModule,
    RedisManagerModule,
    RouterModule,
    TransactionsModule,
    UpgradeModule,
    UpsellModule,
    UsersModule,
  ],
  exports: [HeaderComponent, SidebarComponent],
})
export class PrivateModule {}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EmailTemplateResponse } from '../models/email.model';
import { EmailsService } from '../services/emails.service';

export const emailTemplateDataResolver = (
  route: ActivatedRouteSnapshot
): Observable<EmailTemplateResponse | null> => {
  const router = inject(Router);
  const transactionsService = inject(EmailsService);

  return transactionsService.get$().pipe(
    catchError(error => {
      router.navigate(['/dashboard']);
      console.log(error);
      return of(null);
    })
  );
};

<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>Event Details</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper" *ngIf="data">
      <div class="mt-4">
        <div class="input-line input-file-line">
          <div>
            <span><b>Add new image</b></span
            ><br />
            <span><small>(transparent png recommended)</small></span>
          </div>
          <div class="input-file-wrapper">
            <button class="default-2" (click)="addfile.click()">Upload</button>
            <button
              class="default-2"
              *ngIf="data.urlImage || postImage"
              style="background-color: red"
              (click)="removeImage()">
              Remove
            </button>
            <div
              class="img-empty-container"
              *ngIf="!data.urlImage && !imagePreview; else preview">
              Preview
            </div>
            <ng-template #preview>
              <img
                *ngIf="imagePreview; else defaultimage"
                [src]="imagePreview" />

              <ng-template #defaultimage>
                <img [src]="data.urlImage" />
              </ng-template>
            </ng-template>
            <input
              type="file"
              class="d-none"
              #addfile
              (change)="onFileChange($event)" />
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>ID</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.pvEventId }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Event Code</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.code }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Description</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.description }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Date</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.date | date }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Extra Text</b></span>
          </div>
          <div class="input-line-value">
            <input type="text" [(ngModel)]="data.extraText" />
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Buyer Type Name</b></span>
          </div>
          <div class="input-line-value">
            <input
              type="text"
              [(ngModel)]="data.defaultBuyerTypeName"
              [class.invalid-input]="hashValidator.buyerTypeName" />
            <span class="small error-color" *ngIf="hashValidator.buyerTypeName">
              The entry is not valid
            </span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Buyer Type</b></span>
          </div>
          <div class="input-line-value">
            <input
              type="text"
              [(ngModel)]="data.defaultBuyerType"
              [class.invalid-input]="hashValidator.buyerTypeId" />
            <span class="small error-color" *ngIf="hashValidator.buyerTypeId">
              The entry must be number
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="associated-list input-line">
          <div>
            <span>
              Associated
              <b>{{ data.upsells?.length }} Upsell</b>
            </span>
          </div>
          <div class="associated-list input-line">
            <div class="input-line-value" *ngFor="let u of data.upsells">
              <span>{{ u.name }}</span>
            </div>
            <div *ngIf="!data.upsells?.length">Nothing</div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="associated-list input-line">
          <div>
            <span>
              Associated
              <b>{{ data.upgrades?.length }} Upgrades</b>
            </span>
          </div>
          <div class="associated-list-overflowed">
            <div class="input-line-value" *ngFor="let u of data.upgrades">
              <div class="img-preview">
                <img *ngIf="u.urlImage; else noimg" [src]="u.urlImage" />
                <ng-template #noimg>
                  <div class="img-empty-container">No image</div>
                </ng-template>
              </div>
              <span>{{ u.name }}</span>
            </div>
            <div *ngIf="!data.upsells?.length">Nothing</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-1" (click)="onConfirm()">Save</button>
      <button class="default-1" (click)="onDismiss()">Close</button>
    </div>
  </div>
</div>

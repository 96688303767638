import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../../models/api.model';
import { User } from '../models/users.model';
import { UsersService } from '../services/users.service';

export type UsersResolveValue =
  | { data: ApiResponse<User[]>; error: null }
  | { data: null; error: Error };

export const usersResolver = (): Observable<UsersResolveValue> => {
  const router = inject(Router);
  const usersService = inject(UsersService);
  const page = 1;

  return usersService.getAll$(page).pipe(
    map(data => ({ data, error: null })),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ data: null, error });
    })
  );
};

<div class="modal-container">
  <div class="data-container" style="position: relative">
    <div class="title-wrapper">
      <p>Transactions Details</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper mt-4" *ngIf="data">
      <div class="content-wrapper-col">
        <div class="input-line">
          <div>
            <span
              ><b>Seats ({{ data.seats.length }})</b></span
            >
          </div>
          <div class="input-line-value seats-wrapper" *ngIf="data.seats.length">
            <table class="table">
              <thead>
                <tr>
                  <th>Section</th>
                  <th>Row</th>
                  <th>Seat</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of data.seats">
                  <td>{{ s[0] }}</td>
                  <td>{{ s[1] }}</td>
                  <td>{{ s[2] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Buyer Type</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.buyerTypeId }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Status</b></span>
          </div>
          <div class="input-line-value">
            <span>{{
              transactionStatus.items[data.status.toUpperCase()].name
            }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Original Buyer Type</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.originalBuyerTypeId }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Date</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.createdAt | date }}</span>
          </div>
        </div>
      </div>
      <div class="content-wrapper-col">
        <div class="input-line">
          <div>
            <span><b>Balance</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.balance }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Cart ETAG</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.tdcTransaction.cartEtag }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Credit Available</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.creditAvailable }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Insurance Rain Out</b></span>
          </div>
          <div class="input-line-value">
            <span>{{ data.tdcTransaction.hasInsuranceRainout }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Insurance Rain Out Price</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.insuranceRainoutPrice }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Original Balance</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.originalBalance }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Original Total Sales Revenue</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.originalTotalSalesRevenue }}</span>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Total Sales Revenue</b></span>
          </div>
          <div class="input-line-value">
            <span>$ {{ data.tdcTransaction.totalSalesRevenue }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-1" (click)="onConfirm()">Close</button>
      <button class="default-1 red" (click)="toggleErrorLog()">
        Error Log
      </button>
    </div>

    <div [class.open-log]="isErrorLogOpen" class="error-log-container">
      <div class="title-wrapper">
        <p>Error Log</p>
        <button
          type="button"
          class="btn-modal-header"
          (click)="toggleErrorLog()">
          <span class="ti ti-close"></span>
        </button>
      </div>
      <div class="default-table">
        <div>
          <table>
            <thead>
              <tr>
                <th>Provenue Request Id</th>
                <th>Error Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let e of errors" style="position: relative">
                <td (click)="copyToClipboard(e.pvRequestId)">
                  <span class="unread-error-badge" *ngIf="e.isUnread"></span>

                  <div>
                    <span class="text-center">
                      {{ e.pvRequestId }}
                    </span>
                    <span class="copy-container">
                      <span class="ti ti-clipboard"> </span>
                    </span>
                  </div>
                </td>
                <td (click)="copyToClipboard(e.pvMessage)">
                  <div>
                    <span class="text-left">
                      {{ e.pvMessage }}
                    </span>
                    <span class="copy-container">
                      <span class="ti ti-clipboard"> </span>
                    </span>
                  </div>
                </td>
                <td style="padding-right: 10px">
                  <button class="default-2" (click)="openDrawer(e.error)">
                    Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

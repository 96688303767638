import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Upgrade } from '../../models/upgrade.model';
import { UpgradeService } from '../../services/upgrade.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit, OnDestroy {
  public data: Upgrade;
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  constructor(
    private bsModalRef: BsModalRef,
    private upgradeService: UpgradeService,
    public modalOptions: ModalOptions
  ) {
    this.subscriptions = [];
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }
  ngOnInit(): void {
    if (this.modalOptions && this.modalOptions.initialState) {
      let id = this.modalOptions.initialState['id'];
      if (typeof id === 'string') {
        id = parseInt(id);
      }
      if (typeof id === 'number') {
        const g$ = this.upgradeService.get$(id).subscribe({
          next: (response: Upgrade) => {
            this.data = response;
            console.log(this.data);
          },
          error: err => {
            console.log(err);
          },
        });
        this.subscriptions.push(g$);
      }
    }
  }
  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(id: number) {
    this.upgradeService.delete$(id).subscribe({
      next: (response: Upgrade) => {
        this.onDismiss('success');
      },
      error: err => {
        console.log(err);
      },
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from '../../private/services/auth.service';
import { ConfigurationService } from '../../private/services/configuration.service';
import { UtilsService } from '../../private/services/utils.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: [
    './restore-password.component.scss',
    '../../private/layout/header/header.component.scss',
  ],
})
export class RestorePasswordComponent implements OnDestroy, OnInit {
  public submitResultTypes = {
    error: 'error',
    success: 'success',
  } as const;
  public submitResult:
    | (typeof this.submitResultTypes)[keyof typeof this.submitResultTypes]
    | null = null;
  private defaultErrorMessage =
    'Something went wrong please try again or contact support@3ddigitalvenue.com';
  public submitMessage = '';
  public passwordInputType: 'password' | 'text' = 'password';
  public formData: ReturnType<typeof this.getInitFormData>;
  public hashValidator = {
    username: false,
    token: false,
    password: false,
    confirmPassword: false,
  };
  private subscriptions: Array<Subscription>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public configurationService: ConfigurationService,
    private toastr: ToastrService,
    private utils: UtilsService
  ) {
    this.formData = this.getInitFormData();
    this.subscriptions = [];
  }

  ngOnInit() {
    const queryParamsSub = this.activatedRoute.queryParams.subscribe(param => {
      this.formData = {
        ...this.formData,
        username: param['username'],
        token: param['token'],
      };
    });

    this.subscriptions.push(queryParamsSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public togglePasswordInputType() {
    this.passwordInputType =
      this.passwordInputType === 'password' ? 'text' : 'password';
  }

  public submit(form: NgForm) {
    const hasErrors = this.validatePassword();

    if (hasErrors) {
      return;
    }

    const restorePasswordSub = this.authService
      .restorePassword(this.formData)
      .subscribe({
        next: response => {
          this.router.navigateByUrl(
            `/login?username=${this.formData.username}`
          );
        },
        error: error => {
          console.log(error);
          this.submitResult = this.submitResultTypes.error;
          this.submitMessage = this.defaultErrorMessage;
          this.toastr.error(error?.error?.message || 'Unknown Error');
        },
      });

    this.subscriptions.push(restorePasswordSub);
  }

  private getInitFormData() {
    return {
      username: '',
      token: '',
      password: '',
      confirmPassword: '',
    };
  }

  private validatePassword() {
    const isValidPassword = this.utils.isValidPassword(this.formData.password);
    const isConfirmedPassword =
      this.formData.password === this.formData.confirmPassword;

    this.hashValidator.password = !isValidPassword;
    this.hashValidator.confirmPassword = !isConfirmedPassword;

    const hasErrors = Object.values(this.hashValidator).some(v => !!v);

    return hasErrors;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';
import { Adlink } from '../models/adlink.model';

@Injectable({
  providedIn: 'root',
})
export class AdlinkService {
  private apiRoot = '/bo/summarylink';

  constructor(private http: HttpClient) {}

  public getAll$(page?: number): Observable<ApiResponse<Adlink[]>> {
    let query = '';
    if (page) {
      query = '?page=' + page.toString();
    }
    return this.http.get<ApiResponse<Adlink[]>>(
      environment.apiURl + `${this.apiRoot}${query}`
    );
  }

  public get$(id: number): Observable<ApiResponse<Adlink>> {
    return this.http.get<ApiResponse<Adlink>>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public create$(
    body: Partial<Adlink>,
    file?: File,
    events?: number[]
  ): Observable<ApiResponse<Adlink>> {
    let formData: FormData = new FormData();
    let options = {};
    if (file) {
      formData.append('image', file, file.name);
    }
    formData.append('active', body.active.toString());
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.title) {
      formData.append('title', body.title);
    }
    if (body.link) {
      formData.append('link', body.link);
    }
    if (events && events.length) {
      const formEvents = String(events);
      if (formEvents) {
        formData.append('events', `[${formEvents}]`);
      }
    }

    return this.http.post<ApiResponse<Adlink>>(
      environment.apiURl + `${this.apiRoot}`,
      formData,
      options
    );
  }

  public delete$(id: number): Observable<ApiResponse<Pick<Adlink, 'id'>>> {
    return this.http.delete<ApiResponse<Pick<Adlink, 'id'>>>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public updateEventsBulk$(
    body: { id: number; isEnabled: boolean }[]
  ): Observable<ApiResponse<Adlink[]>> {
    return this.http.patch<ApiResponse<Adlink[]>>(
      environment.apiURl + `${this.apiRoot}/events/bulk`,
      body
    );
  }

  public update$(
    body: Partial<Adlink>,
    file?: File,
    events?: number[]
  ): Observable<ApiResponse<Adlink>> {
    let formData: FormData = new FormData();
    let options = {};

    if (file) {
      formData.append('image', file, file.name);
    }
    if (body.active) {
      formData.append('active', body.active.toString());
    }
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.title) {
      formData.append('title', body.title);
    }
    if (body.link) {
      formData.append('link', body.link);
    }
    if (events && events.length) {
      const formEvents = String(events);
      if (formEvents) {
        formData.append('events', `[${formEvents}]`);
      }
    }
    if (body.urlImage == null) {
      formData.append('urlImage', '');
    } else {
      const endIndex =
        body.urlImage.indexOf('?') > -1 ? body.urlImage.indexOf('?') : Infinity;
      formData.append('urlImage', body.urlImage.substring(0, endIndex));
    }

    return this.http.patch<ApiResponse<Adlink>>(
      environment.apiURl + `${this.apiRoot}/${body.id}`,
      formData,
      options
    );
  }
}

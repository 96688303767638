import { DOCUMENT } from '@angular/common';
import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration, EntityKeys } from '../models/configuration.model';
import { configurationData, configurationSchema } from './configuration-data';

/**
 * Service for managing application configuration. This service initializes the configuration based on the environment
 * extracted from the hostname. It validates the configuration against a predefined schema, fills the configuration
 * with environment-specific values, and declares global CSS variables based on the configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _injector = inject(Injector);
  public environment: EntityKeys;
  public configuration: Configuration;
  private _document = inject(DOCUMENT);
  private _domDocument = document;

  /**
   * Initializes the service by determining the environment from the hostname,
   * loading the respective configuration, validating it, filling it with environment-specific values,
   * and declaring the global CSS variables.
   *
   * @throws If the configuration does not adhere to the predefined schema.
   */
  public init = () => {
    const client: string = this.getEnvironmentFromHostname();
    let configuration = configurationData[client];
    const { success } = configurationSchema.safeParse(configuration);
    const isLocalEnvironment = location.href.includes('local');
    // If after parsing, the club was not found in configurationData, redirect to not found

    if (!success || client === 'default') {
      this.configuration = this.fallbackToDefaultConfig();
      if (!isLocalEnvironment) {
        this.navigateToNotFound();
        return;
      }
    }

    configuration = configuration ?? this.configuration;

    this._document
      .getElementById('appFavicon')
      .setAttribute('href', configuration.favicon);
    this.fillWithEnvConfiguration(configuration);
    this.declareGlobalCssVariables();
  };

  /**
   * Determines the environment based on the hostname in the URL.
   *
   * @returns The name of the environment.
   */
  private getEnvironmentFromHostname() {
    //https://regex101.com/
    // Capture string prefixing ".groupsales.3ddigitalvenue.com".
    // ([^.-]+) is the key expression that extracts the client name

    const regex: RegExp =
      /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(?:pre-)?(?:uat-)?(.*)?(?:.groupsales)\.3ddigitalvenue\.com.*/gm;
    // const client: any = regex.exec(location.href)?.[1] as EntityKeys;
    const client: any = 'chicagocubs';

    const isLocalEnvironment = false;

    // If after matching URL, the client is 'default', meaning it was not found, redirect to not found page
    if (
      // @ts-ignore
      (!isLocalEnvironment && client === 'default') ||
      typeof configurationData[client] == 'undefined'
    ) {
      this.configuration = this.fallbackToDefaultConfig();
      this.navigateToNotFound();
    } else {
      this.environment = client;
    }
    return client;
  }

  /**
   * Fills the service's configuration with values from the specified configuration.
   *
   * @param configuration - The configuration object to load into the service.
   */
  private fillWithEnvConfiguration(configuration: Configuration) {
    this.configuration = {} as Configuration;
    for (const [key, value] of Object.entries(configuration)) {
      this.configuration[key as keyof Configuration] = value;
    }
  }

  /**
   * Declares CSS variables globally based on the 'theme' property of the configuration.
   */
  private declareGlobalCssVariables() {
    const root: HTMLElement | null = this._domDocument.querySelector(':root');

    if (root) {
      const theme = this.configuration['theme'];
      for (const [key, value] of Object.entries(theme)) {
        root.style.setProperty('--' + key, value);
      }
    }
  }

  private fallbackToDefaultConfig() {
    this.environment = 'default';
    return configurationData['default'];
  }

  private navigateToNotFound() {
    const router = this._injector.get(Router);
    router.navigate(['not-found']);
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RedisManagerService } from '../services/redis-manager.service';

export const redisManagerResolver = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const redisManagerService = inject(RedisManagerService);

  return redisManagerService.getAll$().pipe(
    map(response => response),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

<div class="content-header">
  <div class="d-flex align-items-center">
    <h1>Events Management</h1>
  </div>
  <div class="d-flex">
    <button
      class="default-2 big me-4"
      (click)="toggleFuture()"
      [ngClass]="{ off: !queryParams.future }">
      <i class="ti ti-check"></i><span>On going events only</span>
    </button>
    <button type="button" class="default-1" (click)="refreshEvents()">
      Refresh Events <i class="ti ti-reload"></i>
    </button>
  </div>
</div>
<div class="topbar">
  <div class="d-flex flex-row align-items-baseline">
    <div class="me-2">
      <span>Search Event:</span>
    </div>
    <form
      #search="ngForm"
      (ngSubmit)="searchEvent(searchEventInput.value, search)">
      <div class="d-flex flex-row">
        <div class="d-flex me-2">
          <input
            type="text"
            #searchEventInput
            name="code"
            placeholder="Search event using code" />
        </div>
        <div class="d-flex">
          <button class="default-2 search-button me-1" type="submit">
            <i class="ti ti-search"></i> Search
          </button>
          <button
            type="submit"
            class="default-1 search-button"
            *ngIf="activeSearch"
            (click)="restoreTable(searchEventInput)">
            Clear
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="default-table v2">
  <div class="loading" *ngIf="refreshing">
    <div class="text-container">
      <div>
        <span>Loading new events</span>
      </div>
      <div>
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>Code</th>
        <th>Description</th>
        <th>Date</th>
        <th>AdLink</th>
        <th>Upgrades</th>
        <th>Upsell</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of data">
        <td>
          <b>{{ i.code }}</b>
        </td>
        <td>{{ i.description }}</td>
        <td>{{ i.date | date }}</td>
        <td *ngIf="i.adlink">
          {{ i.adlink.title }}
          <button
            type="button"
            class="default-5"
            (click)="openModalEdit(i, 'adlink')"
            title="Edit Adlinks">
            <i class="ti ti-pencil"></i>
          </button>
        </td>
        <td *ngIf="!i.adlink">
          <button
            type="button"
            class="default-5"
            (click)="openModalEdit(i, 'adlink')"
            title="Add Adlinks">
            <i class="ti ti-plus"></i>
          </button>
        </td>
        <td>
          {{ i.upgrades.length }}
          <button
            type="button"
            class="default-5"
            (click)="openModalEdit(i, 'upgrade')"
            title="Edit Upgrades">
            <i class="ti ti-pencil"></i>
          </button>
        </td>
        <td>
          {{ i.upsells.length }}
          <button
            type="button"
            class="default-5"
            (click)="openModalEdit(i, 'upsell')"
            title="Edit Upsell">
            <i class="ti ti-pencil"></i>
          </button>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="default-2"
              (click)="openModalView(i.id)">
              Details
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="legend">
    Showing {{ data.length }} of {{ total_items_in_db }} total Events
  </p>
  <div *ngIf="data.length === 0" class="not-found">
    <span>No event with this code was found</span>
  </div>
</div>
<app-pagination
  [total_items_in_db]="total_items_in_db"
  [items_per_page]="items_per_page"></app-pagination>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription, take } from 'rxjs';
import { PaginationComponent } from '../layout/pagination/pagination.component';
import { ConfigurationService } from '../services/configuration.service';
import { ViewComponent } from './modals/view/view.component';
import {
  SearchQueryParams,
  Transaction,
  TransactionStatusObj,
} from './models/transaction.model';
import { TransactionService } from './services/transactions.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  public data: Array<Transaction>;
  public totalItemsInDb: number;
  public itemsPerPage: number;
  private subscriptions: Array<Subscription>;
  private bsModalRef?: BsModalRef;
  public transactionsStatus: TransactionStatusObj;
  public search: SearchQueryParams;
  public currentPage: number;
  @ViewChild('pagination') pagination: PaginationComponent;
  //ng multiselect
  public eventList: Array<{
    id: number;
    description: string;
  }>;
  public dropdownSettings: any = {};
  public selectedItems = [];

  constructor(
    private transactionService: TransactionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscriptions = [];
    this.initializeSearch();
  }

  ngOnInit(): void {
    //Resolve all events
    let allevents = this.activatedRoute.snapshot.data['events'];
    //ng multiselect: Create list
    this.eventList = allevents.data.map(i => {
      return { code: i.code, description: i.description + ' / ' + i.code };
    });

    this.transactionsStatus = {
      items: {
        PH: { id: 'PH', name: 'Pending Hold' },
        CA: { id: 'CA', name: 'Cancelled' },
        OK: { id: 'OK', name: 'Confirm' },
      },
      list: [
        { id: 'PH', name: 'Pending Hold' },
        { id: 'CA', name: 'Cancelled' },
        { id: 'OK', name: 'Confirm' },
      ],
    };

    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.totalItemsInDb = resolved.metadata.total;
    this.itemsPerPage = resolved.metadata.perPage;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'];
      let resolved = this.activatedRoute.snapshot.data['api'];
      // if (resolved.metadata.currentPage !== this.currentPage) {
      //   this.refresh();
      // }

      if (!params['page'] || params['page'] > resolved.metadata.lastPage) {
        this.router.navigate(['.'], {
          relativeTo: this.activatedRoute,
          queryParams: { page: 1 },
        });
      }

      if (params['page']) {
        this.currentPage = params['page'];
        this.data = resolved.data;
        this.totalItemsInDb = resolved.metadata.total;
        this.itemsPerPage = resolved.metadata.perPage;
        if (this.pagination) {
          this.pagination.refresh(
            this.currentPage,
            this.totalItemsInDb,
            this.itemsPerPage
          );
        }
      }
    });

    const transactionsEventsSub =
      this.transactionService.TransactionEvents$.subscribe(event => {
        if (event.id === 'ERROR_LOG_OPENED') {
          this.data = this.data.map(item => {
            const isTargetTransaction = item.id === event.transactionId;

            return isTargetTransaction
              ? { ...item, hasUnreadErrors: false }
              : item;
          });
        }
      });

    this.subscriptions.push(queryparams$, transactionsEventsSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public refresh() {
    const all$ = this.transactionService
      .getAll$(this.currentPage)
      .subscribe(res => {
        this.data = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
        if (this.pagination) {
          this.pagination.refresh(
            this.currentPage,
            this.totalItemsInDb,
            this.itemsPerPage
          );
        }
        if (this.currentPage > res.metadata.lastPage) {
          this.currentPage = res.metadata.lastPage;
        }
        this.router.navigate(['/private/transactions'], {
          queryParams: { page: this.currentPage },
        });
      });
    this.subscriptions.push(all$);
  }

  private initializeSearch() {
    this.search = {
      params: {
        status: '',
        event: '',
        accountId: '',
        dateFrom: '',
        dateTo: '',
      },
      applied: false,
    };
    this.transactionService.search = JSON.parse(JSON.stringify(this.search));
    //NG MULTISELECT
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'code',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.selectedItems = [];
  }

  public openModalEdit(item: Transaction) {
    const initialState: object = {
      item: item,
      transactionStatus: this.transactionsStatus,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell',
    };
    this.bsModalRef = this.modalService.show(ViewComponent, modalConfig);
  }

  public submit() {
    this.search.applied = true;
    this.transactionService.search = JSON.parse(JSON.stringify(this.search));
    if (this.currentPage != 1) {
      this.router.navigate(['/private/transactions'], {
        queryParams: { page: 1 },
      });
    } else {
      const search$ = this.transactionService
        .getAll$(1, this.search)
        .subscribe(res => {
          this.data = res.data;
          this.totalItemsInDb = res.metadata.total;
          this.itemsPerPage = res.metadata.perPage;
          this.currentPage = 1;
          if (this.pagination) {
            this.pagination.refresh(
              this.currentPage,
              this.totalItemsInDb,
              this.itemsPerPage
            );
          }
        });
      this.subscriptions.push(search$);
    }
  }

  public reset() {
    this.initializeSearch();

    if (this.currentPage != 1) {
      this.router.navigate(['/private/transactions'], {
        queryParams: { page: 1 },
      });
    } else {
      const search$ = this.transactionService.getAll$(1).subscribe(res => {
        this.data = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
        this.currentPage = 1;
        if (this.pagination) {
          this.pagination.refresh(
            this.currentPage,
            this.totalItemsInDb,
            this.itemsPerPage
          );
        }
      });
      this.subscriptions.push(search$);
    }
  }

  public exportAsCsv() {
    this.transactionService.search = JSON.parse(JSON.stringify(this.search));
    this.transactionService
      .exportAsCsv(this.search)
      .pipe(take(1))
      .subscribe(data => {
        this.transactionService.downloadCsv(
          data,
          `transactions_${this.confService.environment}`
        );
      });
  }

  public ondropdownSelect(item) {
    if (item && item.code) {
      this.search.params.event = item.code;
    }
    return;
  }
  public ondropdownUnSelect() {
    this.search.params.event = '';
    return;
  }
}

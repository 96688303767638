import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api.model';
import { User, USER_TYPES } from '../users/models/users.model';

export interface LoginResponseData {
  auth: {
    type: 'bearer' | string;
    token: string;
    refreshToken: string;
    expiresAt: string; // 2025-02-10T02:30:20.745Z
  };
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: LoginResponseData;
  private userLogged$: BehaviorSubject<LoginResponseData>;

  constructor(private http: HttpClient) {
    this.userLogged$ = new BehaviorSubject<LoginResponseData>(undefined);
  }

  public login(loginBody: {
    username: string;
    password: string;
  }): Observable<ApiResponse<LoginResponseData>> {
    return this.http
      .post<
        ApiResponse<LoginResponseData>
      >(environment.apiURl + '/bo/auth/login', loginBody)
      .pipe(
        tap(result => {
          if (result.data) {
            localStorage.setItem(
              environment.authStorageKey,
              JSON.stringify(result.data)
            );

            this.user = result.data;
            this.userLogged$.next(result.data);
          }
        })
      );
  }

  public logout(): Observable<ApiResponse<null>> {
    return this.http
      .post<ApiResponse<null>>(environment.apiURl + '/bo/auth/logout', {})
      .pipe(
        tap(() => {
          localStorage.removeItem(environment.authStorageKey);
          this.userLogged$.next(undefined);
        })
      );
  }

  public getUserLogged(): Observable<LoginResponseData> {
    this.checkCurrentuser();
    return this.userLogged$.asObservable();
  }

  public checkIsSuperadmin(): boolean {
    this.checkCurrentuser();
    return (
      this.user.user.type === USER_TYPES.ADMIN && this.user.user.isSuperadmin
    );
  }

  public checkIsAdmin(): boolean {
    this.checkCurrentuser();
    return this.user.user.type === USER_TYPES.ADMIN;
  }

  public checkCurrentuser() {
    const item = localStorage.getItem(environment.authStorageKey);

    this.user = item ? JSON.parse(item) : undefined;

    if (this.user !== undefined) {
      const check = this.checkTokenExpiration();

      if (item) {
        this.user = check == false ? JSON.parse(item) : undefined;
      }
    }

    this.userLogged$.next(this.user);
  }

  public checkTokenExpiration() {
    if (localStorage.getItem(environment.authStorageKey) === null) {
      return true;
    } else {
      const date = new Date();
      const item = localStorage.getItem(environment.authStorageKey);
      const user = JSON.parse(item);

      if (!user || date > new Date(user.expires_at)) {
        localStorage.removeItem(environment.authStorageKey);
        localStorage.removeItem('path');
        return true;
      }

      return false;
    }
  }

  public resetPassword(
    username: string
  ): Observable<ApiResponse<{ email: string }>> {
    return this.http.post<ApiResponse<{ email: string }>>(
      environment.apiURl + '/bo/auth/reset-password',
      { username }
    );
  }

  public restorePassword(formData: {
    username: string;
    password: string;
    token: string;
  }): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      environment.apiURl + '/bo/auth/restore-password',
      formData
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
interface UserMenu {
  open: boolean;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public username: string;
  public menu: UserMenu = {
    open: false,
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    public configurationService: ConfigurationService
  ) {
    this.menu.open = false;
  }

  ngOnInit(): void {
    this.authService.getUserLogged().subscribe(value => {
      this.username = value?.username;
    });
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}

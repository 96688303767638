import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPaginationResponse } from '../../models/api.model';
import { UtilsService } from '../../services/utils.service';
import {
  SearchQueryParams,
  Transaction,
  TransactionError,
} from '../models/transaction.model';

type TransactionEvent =
  | {
      id: 'ERROR_LOG_OPENED';
      transactionId: number;
    }
  | {
      id: 'ERROR_LOG_CLOSED';
      transactionId: number;
    };

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private apiRoot = '/bo/transactions';
  public search: SearchQueryParams;
  public TransactionEvents$ = new Subject<TransactionEvent>();

  constructor(
    private http: HttpClient,
    private fileSaverService: FileSaverService,
    private utilsService: UtilsService
  ) {}

  public getAll$(
    page?: number,
    searchParams?: SearchQueryParams
  ): Observable<ApiPaginationResponse<Transaction>> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }
    if (searchParams) {
      if (searchParams.params.status)
        query += '&status=' + searchParams.params.status;
      if (searchParams.params.accountId)
        query += '&accountId=' + searchParams.params.accountId;
      if (searchParams.params.dateFrom)
        query += '&dateFrom=' + searchParams.params.dateFrom;
      if (searchParams.params.dateTo)
        query += '&dateTo=' + searchParams.params.dateTo;
      if (searchParams.params.event)
        query += '&event=' + searchParams.params.event;
    }
    return this.http.get<ApiPaginationResponse<Transaction>>(
      environment.apiURl + `${this.apiRoot}${query}`
    );
  }

  public get$(id?: number): Observable<Transaction> {
    return this.http.get<Transaction>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public emit(event: TransactionEvent) {
    this.TransactionEvents$.next(event);
  }

  public getErrors$(id: number): Observable<{ data: Array<TransactionError> }> {
    return this.http
      .get<{
        data: Array<TransactionError>;
      }>(environment.apiURl + `${this.apiRoot}/errors/${id}`)
      .pipe(
        finalize(() =>
          this.TransactionEvents$.next({
            id: 'ERROR_LOG_OPENED',
            transactionId: id,
          })
        )
      );
  }

  public exportAsCsv(searchParams?: SearchQueryParams): Observable<any> {
    let query = '';
    if (searchParams) {
      if (searchParams.params.status)
        query += '&status=' + searchParams.params.status;
      if (searchParams.params.accountId)
        query += '&accountId=' + searchParams.params.accountId;
      if (searchParams.params.dateFrom)
        query += '&dateFrom=' + searchParams.params.dateFrom;
      if (searchParams.params.dateTo)
        query += '&dateTo=' + searchParams.params.dateTo;
      if (searchParams.params.event)
        query += '&event=' + searchParams.params.event;
    }
    return this.http.get(
      environment.apiURl + `${this.apiRoot}/exports/csv?${query}`,
      { responseType: 'text' }
    );
  }

  public downloadCsv = (data: string, fileName: string) => {
    const currentDayFormatted = this.utilsService.getCurrentDayFormatted();
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    this.fileSaverService.save(
      blob,
      `${fileName}_export_${currentDayFormatted}.csv`
    );
  };
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QueryParams } from '../models/event.model';
import { EventsService } from '../services/events.service';
import { PrivateSellingService } from '../services/private-selling.service';

export const eventsResolver = () => {
  const router = inject(Router);
  const eventsService = inject(EventsService);
  const privateSellingService = inject(PrivateSellingService);
  const queryParams: QueryParams = {
    future: true,
  };

  return forkJoin({
    eventsLists: eventsService.getAll$(1, queryParams),
    clientData: privateSellingService.getClientWithWhitelist(),
  }).pipe(
    map(data => {
      if (!privateSellingService.initialWhitelist().length) {
        const whitelistCopy = [
          ...(data.clientData.data.privateSellingWhitelist || []),
        ];
        privateSellingService.initialWhitelist.set(whitelistCopy);
      }

      return data;
    }),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

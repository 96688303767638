import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RedisRecord } from '../../models/redis-manager.model';
import { RedisManagerService } from '../../services/redis-manager.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrl: './delete.component.scss',
})
export class DeleteComponent implements OnInit, OnDestroy {
  public data: RedisRecord;
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  private waitingApi: boolean;
  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private redisManagerService: RedisManagerService,
    private toastrService: ToastrService
  ) {
    this.subscriptions = [];
    this.waitingApi = false;
  }

  get json() {
    if (!this.data) {
      return '';
    }
    let json = null;

    try {
      json = JSON.parse(this.data?.value);
    } catch (error) {
      json = this.data?.value;
    }

    return JSON.stringify(json, undefined, 2);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  ngOnInit(): void {
    if (this.modalOptions && this.modalOptions.initialState) {
      let key = this.modalOptions.initialState['key'] as string;
      const g$ = this.redisManagerService.get$(key).subscribe({
        next: response => {
          this.data = response.data;
        },
        error: () => {
          this.toastrService.error(
            `An error occurred trying to get the redis key: ${key}`,
            'Error'
          );
        },
      });
      this.subscriptions.push(g$);
    }
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(key: string) {
    if (this.waitingApi) return;
    this.waitingApi = true;

    this.redisManagerService.delete$(key).subscribe({
      next: () => {
        this.onDismiss('success');
      },
      error: () => {
        this.toastrService.error(
          `An error occurred trying to delete the redis key: ${key}`,
          'Error'
        );
      },
    });
  }
}

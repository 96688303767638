import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdlinkService } from '../services/adlink.service';

export const adLinkResolver = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const adlinkService = inject(AdlinkService);
  let page = 1;

  if (route.queryParams['page']) {
    page = route.queryParams['page'];
  }

  return adlinkService.getAll$(page).pipe(
    map(list => list),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

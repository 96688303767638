import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-error',
  templateUrl: './delete-error.component.html',
  styleUrls: ['./delete-error.component.css'],
})
export class DeleteErrorComponent {
  protected data: { id: number };
  constructor(private bsModalRef: BsModalRef) {}

  public onDismiss() {
    this.bsModalRef.hide();
  }
}

import { Component, inject } from '@angular/core';
import { ConfigurationService } from '../private/services/configuration.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  public configurationService = inject(ConfigurationService);
}

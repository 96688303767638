import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';
import { ApiResponse } from '../models/api.model';
import { AuthService } from '../services/auth.service';
import { DeleteErrorComponent } from './modals/delete-error/delete-error.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { EditBulkConfirmComponent } from './modals/edit-bulk-confirm/edit-bulk-confirm.component';
import { EditBulkComponent } from './modals/edit-bulk/edit-bulk.component';
import { EditComponent } from './modals/edit/edit.component';
import { ManagePrivateSellingComponent } from './modals/manage-private-selling/manage-private-selling.component';
import { ViewComponent } from './modals/view/view.component';
import { Client } from './models/client.model';
import { QueryParams, Event as SaleEvent } from './models/event.model';
import { BulkEditAction, EventsService } from './services/events.service';
import { PrivateSellingService } from './services/private-selling.service';

type ResolverData = {
  eventsLists: ApiResponse<SaleEvent[]>;
  clientData: ApiResponse<Client>;
};

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  public eventsLists: SaleEvent[];
  public clientData: Client;
  public totalItemsInDb: number;
  public itemsPerPage: number;
  private subscriptions: Array<Subscription>;
  private bsModalRef?: BsModalRef;
  public refreshing: boolean;
  private currentPage: number;
  public queryParams: QueryParams;
  private dataBuffer: any;
  public activeSearch: boolean;

  constructor(
    protected eventsService: EventsService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private privateSellingService: PrivateSellingService,
    protected authService: AuthService
  ) {
    this.subscriptions = [];
    this.refreshing = false;
    this.queryParams = { future: true };
    const s1 = this.eventsService.eventDeletedEvent.subscribe(this.deleteEvent);
    const s2 = this.eventsService.eventBulkEditEvent.subscribe(this.onEditBulk);
    const s3 = this.eventsService.eventBulkEditConfirmEvent.subscribe(
      this.manageBulk
    );
    this.subscriptions.push(s1, s2, s3);
  }

  ngOnInit(): void {
    const resolved: ResolverData = this.activatedRoute.snapshot.data['api'];
    this.eventsLists = resolved?.eventsLists.data;
    this.clientData = resolved?.clientData.data;

    this.dataBuffer = resolved;
    this.totalItemsInDb = resolved?.eventsLists.metadata.total;
    this.itemsPerPage = resolved?.eventsLists.metadata.perPage;
    this.currentPage = 1;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      if (params['page']) {
        this.currentPage = params['page'];
        const data: ResolverData = this.activatedRoute.snapshot.data['api'];
        const api = data.eventsLists;

        if (this.currentPage && api.metadata.currentPage !== this.currentPage) {
          this.getAll();
        }
      }
    });

    const privateSellingSubscription =
      this.privateSellingService.privateSellingToggleEvent.subscribe(
        isPrivateSellingEnabled => {
          this.clientData.isPrivateSellingEnabled = isPrivateSellingEnabled;
        }
      );

    this.subscriptions.push(queryparams$, privateSellingSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public openModalView(eventId: number) {
    const initialState = { eventId };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'view-event modal-lg',
    };
    this.bsModalRef = this.modalService.show(ViewComponent, modalConfig);
    const modalSubscription = this.bsModalRef.onHide.subscribe(
      (action: any) => {
        let updatedValue = this.bsModalRef.content.result as SaleEvent;
        let eventIndex = this.eventsLists.findIndex(
          event => event.pvEventId === updatedValue.pvEventId
        );
        this.eventsLists[eventIndex] = updatedValue;
        modalSubscription.unsubscribe();
      }
    );
  }
  public openModalEdit(event: SaleEvent, type: string) {
    const initialState: object = {
      event: event,
      type: type,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-event modal-lg',
    };
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide();
  }

  public refreshEvents() {
    this.refreshing = true;
    const r$ = this.eventsService.refresh$().subscribe(res => {
      this.getAll();
    });
    this.subscriptions.push(r$);
  }
  public toggleFuture() {
    this.queryParams.future = !this.queryParams.future;
    this.refreshing = true;
    this.getAll();
  }

  public searchEvent(value: string, form: NgForm): void {
    this.activeSearch = true;
    this.refreshing = true;
    const searchEvent$ = this.eventsService.searchEvent$(value).subscribe(
      res => {
        this.refreshing = false;
        this.eventsLists = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
      },
      error => {
        if (error.code === 'E_SEARCH_EVENT_BAD_VALUE') {
          this.refreshing = false;
          console.error(error.message);
        }
      }
    );

    this.subscriptions.push(searchEvent$);
  }

  public restoreTable(input: HTMLInputElement): void {
    this.activeSearch = false;
    input.value = '';
    this.getAll();
  }

  public isSelected(id: number): boolean {
    let isSelected = false;
    this.eventsService.selectedEventsIds.pipe(take(1)).subscribe({
      next: events => {
        if (events.includes(id)) {
          isSelected = true;
        }
      },
    });

    return isSelected;
  }

  public isAllSelected(): boolean {
    let isAllSelected = false;
    this.eventsService.selectedEventsIds.pipe(take(1)).subscribe({
      next: events => {
        if (events?.length === this.eventsLists?.length) {
          isAllSelected = true;
        }
      },
    });

    return isAllSelected;
  }

  public onEditBulkClick() {
    const selectedEventIds = this.eventsService.getSelectedEventIds();
    const events = this.eventsLists.filter(ev =>
      selectedEventIds.includes(ev.id)
    );
    const eventDescriptions = events.map(ev => ev.description);
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      keyboard: true,
      ignoreBackdropClick: true,
      initialState: {
        eventDescriptions,
      },
    };
    this.bsModalRef = this.modalService.show(EditBulkComponent, modalConfig);
  }

  public onEditBulk = (action: BulkEditAction) => {
    this.bsModalRef.hide();

    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      const modalConfig: ModalOptions = {
        animated: true,
        backdrop: true,
        keyboard: true,
        ignoreBackdropClick: true,
        initialState: {
          action,
        },
        class: 'preview-modal modal-dialog-centered',
      };

      this.bsModalRef = this.modalService.show(
        EditBulkConfirmComponent,
        modalConfig
      );
    });
  };

  public manageBulk = (action: BulkEditAction) => {
    this.bsModalRef.hide();

    if (action === 'delete') {
      this.eventsService.deleteBulk().subscribe(response => {
        const selectedEventIds = this.eventsService.getSelectedEventIds();
        this.eventsLists = this.eventsLists.filter(
          ev => !selectedEventIds.includes(ev.id)
        );
        this.eventsService.unSelectAllEvents();
        this.toastr.info(response.message);
      });
    } else {
      this.eventsService.editBulk(action).subscribe(() => {
        this.eventsService.unSelectAllEvents();
        this.toastr.info(`All events were succesfully ${action + 'd'}`);
      });
    }
  };

  public onAllEventCheckboxChange(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.checked) {
      this.eventsService.selectAllEvents(this.eventsLists);
    } else {
      this.eventsService.unSelectAllEvents();
    }
  }

  public onEventCheckboxChange(event: Event, id: number) {
    const element = event.target as HTMLInputElement;
    if (element.checked) {
      this.eventsService.addSelectedEvent(id);
    } else {
      this.eventsService.removeSelectedEvent(id);
    }
  }

  public onEventEnabledToggle(event: SaleEvent) {
    // We do a copy instead of directly modifying the object to avoid changing state
    // before knowing that the HTTP request is succesful
    const eventCopy = { id: event.id, isEnabled: !event.isEnabled };

    this.eventsService.toggleEnableOnDb(eventCopy).subscribe({
      next: () => {
        const eventIdx = this.eventsLists.findIndex(ev => ev.id === event.id);
        this.eventsLists[eventIdx].isEnabled = !event.isEnabled;
        const action = this.eventsLists[eventIdx].isEnabled
          ? 'enabled'
          : 'disabled';
        this.toastr.info(`Event was succesfully ${action}`);
      },
      error: ({ error }) => {
        const message = error.errors[0].message ?? 'Unexpected error';
        this.toastr.error(message);
      },
    });
  }

  public onEventDeleteClick(event: SaleEvent) {
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        action: 'preview',
        data: event,
      },
      class: 'preview-modal',
    };

    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
  }

  public deleteEvent = (id: number) => {
    this.eventsService.removeEventOnDb(id).subscribe({
      next: res => {
        this.bsModalRef.hide();
        this.eventsLists = this.eventsLists.filter(ev => ev.id !== id);
        this.toastr.info(`Event was succesfully deleted`);
      },
      error: err => {
        this.bsModalRef.hide();

        const s = this.bsModalRef.onHidden.subscribe(() => {
          const modalConfig: ModalOptions = {
            animated: true,
            backdrop: true,
            keyboard: true,
            ignoreBackdropClick: true,
            initialState: { action: 'preview', data: { id } },
            class: 'preview-modal',
          };
          this.modalService.show(DeleteErrorComponent, modalConfig);
        });

        this.subscriptions.push(s);
      },
    });
  };

  public onPrivateSellingClick = () => {
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'preview-modal',
      initialState: {
        whitelist: this.clientData.privateSellingWhitelist ?? [],
        initialWhitelist: [...(this.clientData.privateSellingWhitelist ?? [])],
        isPrivateSellingEnabled: this.clientData.isPrivateSellingEnabled,
        clientId: this.clientData.id,
      },
    };

    this.bsModalRef = this.modalService.show(
      ManagePrivateSellingComponent,
      modalConfig
    );

    this.bsModalRef.content.modifiedWhitelist.subscribe(value => {
      this.clientData.privateSellingWhitelist = [...value];
    });
  };

  private refreshListOnHide() {
    const onH$ = this.bsModalRef.onHide.subscribe(() => {
      if (this.bsModalRef.content.closeReason == 'success') {
        const a$ = this.eventsService
          .getAll$(this.currentPage, this.queryParams)
          .subscribe(res => {
            this.eventsLists = res.data;
            this.totalItemsInDb = res.metadata.total;
            this.itemsPerPage = res.metadata.perPage;
          });
        this.subscriptions.push(a$);
      }
    });

    this.subscriptions.push(onH$);
  }

  private getAll() {
    const all$ = this.eventsService
      .getAll$(this.currentPage, this.queryParams)
      .subscribe(res => {
        this.refreshing = false;
        this.eventsLists = res.data;
        this.eventsService.unSelectAllEvents();
        this.dataBuffer = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
      });
    this.subscriptions.push(all$);
  }
}

import { Component } from '@angular/core';
import { Event } from '../../models/event.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
})
export class DeleteComponent {
  public data: Event;
  constructor(
    private bsModalRef: BsModalRef,
    private eventsService: EventsService
  ) {}

  public onDismiss() {
    this.bsModalRef.hide();
  }

  public onConfirm(id: number) {
    this.eventsService.eventDeletedEvent.emit(id);
  }
}

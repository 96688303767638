<div class="content-header">
  <div class="d-flex align-items-center">
    <h1>Emails</h1>
  </div>
  <div></div>
</div>

<div class="topbar">
  <div class="d-flex flex-row align-items-baseline">
    <div class="me-2">
      <span class="template-name">Confirmation email</span>
    </div>
  </div>
  <div class="d-flex space-between">
    <button type="button" class="default-2" (click)="openModalEdit()">
      <i class="ti ti-pencil"></i> Edit
    </button>
    <button type="button" class="default-2" (click)="sendDemoEmail()">
      <i class="ti ti-email"></i> Send email demo
    </button>
    <button type="button" class="default-2" (click)="openModalPreview()">
      <i class="ti ti-eye"></i> Preview
    </button>
  </div>
</div>
<div>
  <div class="first-row">
    <div class="logo-container">
      <div><b>Logo URL:</b></div>
      <div
        class="img-empty-container"
        *ngIf="!data.emailData.logoUrl; else logoPreview">
        No logo defined
      </div>
      <ng-template #logoPreview>
        <img [src]="data.emailData.logoUrl" />
      </ng-template>
    </div>

    <div class="banner-container">
      <div><b>Header Banner URL:</b></div>
      <div
        class="img-empty-container"
        *ngIf="!data.emailData.headerUrl; else headerPreview">
        No header defined
      </div>
      <ng-template #headerPreview>
        <img [src]="data.emailData.headerUrl" />
      </ng-template>
    </div>

    <div class="banner-container">
      <div><b>Footer Banner URL:</b></div>
      <div
        class="img-empty-container"
        *ngIf="!data.emailData.footerUrl; else footerPreview">
        No footer defined
      </div>
      <ng-template #footerPreview>
        <img [src]="data.emailData.footerUrl" />
      </ng-template>
    </div>
  </div>

  <div class="second-row">
    <div class="text-container">
      <div><b>Legal text:</b></div>
      <div class="img-empty-container">
        {{
          data.emailData.legalText
            ? data.emailData.legalText
            : 'No legal text defined'
        }}
      </div>
    </div>

    <div class="text-container">
      <div><b>Greeting text:</b></div>
      <div class="img-empty-container">
        {{
          data.emailData.greetingText
            ? data.emailData.greetingText
            : 'No greeting text defined'
        }}
      </div>
    </div>
  </div>
</div>

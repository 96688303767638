import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PaginationComponent } from '../layout/pagination/pagination.component';
import { CreateComponent } from './modals/create/create.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { EditComponent } from './modals/edit/edit.component';
import { Upsell } from './models/upsell.model';
import { UpsellService } from './services/upsell.service';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss'],
})
export class UpsellComponent implements OnInit, OnDestroy {
  public data: Array<Upsell>;
  public totalItemsInDb: number;
  public itemsPerPage: number;
  private bsModalRef?: BsModalRef;
  private subscriptions: Array<Subscription>;
  public currentPage: number;
  @ViewChild('pagination') pagination: PaginationComponent;

  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private upsellService: UpsellService,
    private router: Router
  ) {
    this.data = [];
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }
  ngOnInit(): void {
    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.totalItemsInDb = resolved.metadata.total;
    this.itemsPerPage = resolved.metadata.perPage;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      let resolved = this.activatedRoute.snapshot.data['api'];

      if (!params['page'] || params['page'] > resolved.metadata.lastPage) {
        this.router.navigate(['.'], {
          relativeTo: this.activatedRoute,
          queryParams: { page: 1 },
        });
      }

      if (params['page']) {
        this.currentPage = params['page'];
        this.data = resolved.data;
        this.totalItemsInDb = resolved.metadata.total;
        this.itemsPerPage = resolved.metadata.perPage;
        if (this.pagination) {
          this.pagination.refresh(
            this.currentPage,
            this.totalItemsInDb,
            this.itemsPerPage
          );
        }
      }
    });
    this.subscriptions.push(queryparams$);
  }

  public openModalDelete(upsellId: number) {
    const initialState: object = {
      id: upsellId,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell',
    };
    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
    this.refreshListOnHide(1);
  }
  public openModalEdit(item: Upsell) {
    const initialState: object = {
      item: JSON.parse(JSON.stringify(item)),
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell',
    };
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide(this.currentPage);
  }

  public openModalCreate() {
    const initialState: object = {};
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'create-upsell',
    };
    this.bsModalRef = this.modalService.show(CreateComponent, modalConfig);
    this.refreshListOnHide(1);
  }

  public toggleActive(item: Upsell) {
    item.active = !item.active;
    const update$ = this.upsellService.update$(item, []).subscribe(res => {
      console.log(res);
    });
    this.subscriptions.push(update$);
  }

  private refreshListOnHide(page?: string | number) {
    const onH$ = this.bsModalRef.onHide.subscribe(response => {
      if (this.bsModalRef.content.closeReason == 'success') {
        this.getall(page);
      }
    });
    this.subscriptions.push(onH$);
  }
  private getall(page?) {
    if (!page) page = this.currentPage;

    const all$ = this.upsellService.getAll$(page).subscribe({
      next: (res: any) => {
        this.data = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
        if (this.pagination) {
          this.pagination.refresh(
            this.currentPage,
            this.totalItemsInDb,
            this.itemsPerPage
          );
        }
        if (this.currentPage > res.metadata.lastPage) {
          this.currentPage = page;
        }
        this.router.navigate(['/private/upsell'], {
          queryParams: { page: this.currentPage },
        });
      },
      error: err => {
        console.log(err);
      },
    });
    this.subscriptions.push(all$);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { ModalUserComponent } from './modals/modal-user/modal-user.component';
import { UploadResultComponent } from './modals/upload-result/upload-result.component';
import { UsersComponent } from './users.component';

@NgModule({
  declarations: [UsersComponent, ModalUserComponent, UploadResultComponent],
  imports: [
    CommonModule,
    PaginationModule,
    ModalEventSelectorModule,
    FormsModule,
    NgxCsvParserModule,
  ],
  exports: [UsersComponent],
})
export class UsersModule {}

<div class="content-header">
  <div class="d-flex align-items-center">
    <h1>Events Management</h1>
  </div>
  <div class="d-flex justify-content-center align-items-center gap-2">
    <button
      type="button"
      class="default-1 d-flex justify-content-center align-items-center ms-0 gap-1"
      (click)="toggleFuture()"
      [ngClass]="{ off: !queryParams.future }">
      <i class="ti ti-check ms-0"></i><span>On going events only</span>
    </button>
    @if (authService.checkIsAdmin() || authService.checkIsSuperadmin()) {
      <button
        type="button"
        class="default-1 d-flex justify-content-center align-items-center ms-0 gap-1"
        (click)="onPrivateSellingClick()">
        <i class="ti ti-shield ms-0"></i>
        <span>
          {{
            clientData.isPrivateSellingEnabled
              ? 'Manage private selling'
              : 'Start private selling'
          }}</span
        >
      </button>
    }
    <button
      type="button"
      class="default-1 d-flex justify-content-center align-items-center ms-0 gap-1"
      (click)="refreshEvents()">
      <i
        class="ti ti-reload d-flex justify-content-center align-items-center ms-0"></i>
      <span>Refresh Events</span>
    </button>
  </div>
</div>
<div class="topbar">
  <div class="d-flex flex-row align-items-baseline w-100">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex gap-2 w-100">
        <form
          #search="ngForm"
          (ngSubmit)="searchEvent(searchEventInput.value, search)">
          <div class="d-flex flex-row gap-2">
            <div class="d-flex me-2">
              <input
                type="text"
                #searchEventInput
                name="code"
                placeholder="Search event using code" />
            </div>
            <div class="d-flex gap-2">
              <button class="default-2 search-button me-1" type="submit">
                <i class="ti ti-search"></i> Search
              </button>
              <button
                type="submit"
                class="default-1 search-button"
                *ngIf="activeSearch"
                (click)="restoreTable(searchEventInput)">
                Clear
              </button>
            </div>
          </div>
        </form>
        @if (eventsService.selectedEventsIds | async; as selectedEventsIds) {
          <button
            class="default-2"
            (click)="onEditBulkClick()"
            [ngClass]="{
              off:
                !eventsLists ||
                !eventsLists.length ||
                !selectedEventsIds.length,
            }">
            Edit all selected
          </button>
        }
      </div>
      <div class="refresh-events-disclaimer me-3">
        By refreshing the events you will pull all the events that the MMC
        Agency has access to in ProVenue.
      </div>
    </div>
  </div>
</div>
<div class="default-table v2 events-table">
  <div class="loading" *ngIf="refreshing">
    <div class="text-container">
      <div>
        <span>Loading new events</span>
      </div>
      <div>
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
  <div class="table-wrap">
    <div *ngIf="eventsLists?.length === 0" class="not-found">
      <span>No events found. Refresh events or check the used code.</span>
    </div>
    <table *ngIf="eventsLists?.length !== 0">
      <thead>
        <tr>
          <th>
            <div class="d-flex justify-content-center align-items-center">
              <input
                class="form-check-input form-check-input-header"
                type="checkbox"
                aria-label="Toggle event selection"
                [checked]="isAllSelected()"
                (change)="onAllEventCheckboxChange($event)" />
            </div>
          </th>
          <th>Code</th>
          <th>Description</th>
          <th>Date</th>
          <th>AdLink</th>
          <th>Upgrades</th>
          <th>Upsell</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr *ngFor="let i of eventsLists">
          <td class="d-flex justify-content-center align-items-center">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="'checkbox' + i.id"
              [value]="i.id"
              #checkbox
              [checked]="isSelected(i.id)"
              (change)="onEventCheckboxChange($event, i.id)"
              aria-label="Toggle event selection" />
          </td>
          <td>
            <b>{{ i.code }}</b>
          </td>
          <td>{{ i.description }}</td>
          <td>{{ i.date | date }}</td>
          <td *ngIf="i.adlink">
            {{ i.adlink.title }}
            <button
              type="button"
              class="default-5"
              (click)="openModalEdit(i, 'adlink')"
              title="Edit Adlinks">
              <i class="ti ti-pencil"></i>
            </button>
          </td>
          <td *ngIf="!i.adlink">
            <button
              type="button"
              class="default-5"
              (click)="openModalEdit(i, 'adlink')"
              title="Add Adlinks">
              <i class="ti ti-plus"></i>
            </button>
          </td>
          <td>
            {{ i.upgrades.length }}
            <button
              type="button"
              class="default-5"
              (click)="openModalEdit(i, 'upgrade')"
              title="Edit Upgrades">
              <i class="ti ti-pencil"></i>
            </button>
          </td>
          <td>
            {{ i.upsells.length }}
            <button
              type="button"
              class="default-5"
              (click)="openModalEdit(i, 'upsell')"
              title="Edit Upsell">
              <i class="ti ti-pencil"></i>
            </button>
          </td>
          <td class="p-0 ps-1 pe-1">
            <div class="d-flex justify-content-center align-items-center">
              <button
                type="button"
                class="default-2"
                (click)="openModalView(i.id)">
                Details
              </button>
            </div>
          </td>
          <td class="p-0 ps-1 pe-1">
            <div
              class="d-flex justify-content-center text-nowrap align-items-center">
              <button
                type="button"
                [ngClass]="{ disabled: !i.isEnabled }"
                class="default-2 fixed text-nowrap"
                (click)="onEventEnabledToggle(i)">
                {{ i.isEnabled ? 'Disable' : 'Enable' }}
              </button>
            </div>
          </td>
          <td class="p-0 ps-1 pe-1">
            <div class="d-flex justify-content-center align-items-center">
              <button
                type="button"
                class="default-2 red"
                (click)="onEventDeleteClick(i)">
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="legend">
    Showing {{ eventsLists?.length }} of {{ total_items_in_db }} total Events
  </p>
</div>
<app-pagination
  [total_items_in_db]="total_items_in_db"
  [items_per_page]="items_per_page"></app-pagination>

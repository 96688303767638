<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>Are you sure you want to delete this redis key?</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss('cancel')"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper">
      <div class="mt-4">
        <div class="input-line">
          <div>
            <span><b>Key</b></span>
          </div>
          <div>
            <p>{{ data?.key }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Value</b></span>
          </div>
          <div>
            <pre class="pre">{{ json }}</pre>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
      <button class="default-1" (click)="onConfirm(data.key)">
        Permanently delete
      </button>
    </div>
  </div>
</div>

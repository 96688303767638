import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subscription } from 'rxjs';
import {
  ModalAction,
  ModalUserComponent,
} from './modals/modal-user/modal-user.component';
import { UploadResultComponent } from './modals/upload-result/upload-result.component';
import { User } from './models/users.model';
import { UsersResolveValue } from './resolvers/users.resolver';
import { UsersService } from './services/users.service';

const CSV_HEADERS = ['username', 'password', 'email', 'type'];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  public data: Array<User>;
  public refreshing = false;
  public activeSearch: boolean;
  private bsModalRef: BsModalRef;
  private subscriptions: Array<Subscription> = [];
  private currentPage: number;
  public totalItemsInDb: number;
  public itemsPerPage: number;

  @ViewChild('csvFile', { static: false })
  csvFileRef!: ElementRef;

  constructor(
    private usersService: UsersService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private ngxCsvParser: NgxCsvParser,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const resolved: UsersResolveValue =
      this.activatedRoute.snapshot.data['api'];

    if (resolved.error) {
      this.toastr.error('Error loading users');
    }

    this.data = resolved.data.data;
    this.totalItemsInDb = resolved.data.metadata.total;
    this.itemsPerPage = resolved.data.metadata.perPage;
    this.currentPage = 1;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public searchUser(value: string): void {
    this.activeSearch = true;
    this.refreshing = true;

    const searchUser$ = this.usersService.searchUser$(value).subscribe(
      res => {
        this.refreshing = false;
        this.data = res.data;
        this.totalItemsInDb = res.metadata.total;
        this.itemsPerPage = res.metadata.perPage;
      },
      error => {
        this.toastr.error(error?.error?.message, 'Error searching users');
      }
    );

    this.subscriptions.push(searchUser$);
  }

  public onFileChange(event) {
    if (event.target.files.length > 1) {
      this.toastr.warning('You can upload only one file');
    }

    if (event.target.files[0]) {
      const parseCsvSub = this.ngxCsvParser
        .parse(event.target.files[0], {
          header: true,
          delimiter: ';',
          encoding: 'utf8',
        })
        .pipe(finalize(() => (this.csvFileRef.nativeElement.value = '')))
        .subscribe({
          next: (result: Array<User>): void => {
            const commaSeparatedHeaderError = CSV_HEADERS.join(',');
            const hasParseError =
              Object.keys(result[0])[0] === commaSeparatedHeaderError;

            this.openModalCsvResult(hasParseError ? [] : result);
          },
          error: (error: NgxCSVParserError): void => {
            this.toastr.error(error?.message, 'Error parsing file');
            console.log('Error parsing file', error);
          },
        });

      this.subscriptions.push(parseCsvSub);
    }
  }

  private openModalCsvResult(data: Array<User>) {
    const modalConfig = this.getModalConfig({ data });
    this.bsModalRef = this.modalService.show(
      UploadResultComponent,
      modalConfig
    );
    this.refreshListOnHide();
  }

  public clearSearch(input: HTMLInputElement): void {
    this.activeSearch = false;
    input.value = '';
    this.getall();
  }

  public openModalEdit(user: User) {
    const modalConfig = this.getModalConfig({ user, action: 'edit' });
    this.bsModalRef = this.modalService.show(ModalUserComponent, modalConfig);
    this.refreshListOnHide();
  }

  public openModalCreate() {
    const modalConfig = this.getModalConfig({ user: null, action: 'create' });
    this.bsModalRef = this.modalService.show(ModalUserComponent, modalConfig);
    this.refreshListOnHide();
  }

  public openModalDelete(user: User) {
    const modalConfig = this.getModalConfig({ user, action: 'delete' });
    this.bsModalRef = this.modalService.show(ModalUserComponent, modalConfig);
    this.refreshListOnHide();
  }

  private refreshListOnHide() {
    const onH$ = this.bsModalRef.onHide.subscribe(() => {
      if (this.bsModalRef.content.closeReason == 'success') {
        this.getall();
      }
    });

    this.subscriptions.push(onH$);
  }

  private getModalConfig(
    initialState?:
      | {
          user: User | null;
          action: ModalAction;
        }
      | { data: Array<User> }
  ): ModalOptions {
    return {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'user modal-lg',
      ...(initialState ? { initialState } : {}),
    };
  }

  private getall() {
    const all$ = this.usersService.getAll$(this.currentPage).subscribe(res => {
      this.refreshing = false;
      this.data = res.data;
      this.totalItemsInDb = res.metadata.total;
      this.itemsPerPage = res.metadata.perPage;
    });

    this.subscriptions.push(all$);
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Adlink } from 'src/app/private/adlink/models/adlink.model';
import { AdlinkService } from 'src/app/private/adlink/services/adlink.service';
import { ApiResponse } from 'src/app/private/models/api.model';
import { Upgrade } from 'src/app/private/upgrade/models/upgrade.model';
import { UpgradeService } from 'src/app/private/upgrade/services/upgrade.service';
import { Upsell } from 'src/app/private/upsell/models/upsell.model';
import { UpsellService } from 'src/app/private/upsell/services/upsell.service';
import { CheckedEditItem, Event } from '../../models/event.model';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  @Input() type: 'upsell' | 'upgrade' | 'adlink';
  @Input() event: Event;
  public checked: CheckedEditItem;
  public upgradeList: ApiResponse<Upgrade[]>;
  public upsellList: ApiResponse<Upsell[]>;
  public adLinkList: ApiResponse<Adlink[]>;
  private subscriptions: Array<Subscription>;
  private closeReason: 'success' | 'cancel';

  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private eventsService: EventsService,
    private adlinkService: AdlinkService,
    private upgradeService: UpgradeService,
    private upsellService: UpsellService
  ) {
    this.subscriptions = [];
    this.checked = { list: {}, selected: { total: 0, ids: [] } };

    this.upgradeList = {
      metadata: {
        total: 0,
        perPage: 0,
        currentPage: 1,
        lastPage: 1,
        firstPage: 1,
        hasMorePages: false,
      },
      data: [],
      message: 'OK',
    };
    this.upsellList = {
      metadata: {
        total: 0,
        perPage: 0,
        currentPage: 1,
        lastPage: 1,
        firstPage: 1,
        hasMorePages: false,
      },
      data: [],
      message: 'OK',
    };
    this.adLinkList = {
      metadata: {
        total: 0,
        perPage: 0,
        currentPage: 1,
        lastPage: 1,
        firstPage: 1,
        hasMorePages: false,
      },
      data: [],
      message: 'OK',
    };
  }

  ngOnInit(): void {
    if (this.type === 'upsell') {
      const upsell$ = this.upsellService.getAll$(1).subscribe(res => {
        this.upsellList = res;
      });
      this.subscriptions.push(upsell$);
    } else if (this.type === 'upgrade') {
      const upgrade$ = this.upgradeService.getAll$(1).subscribe(res => {
        this.upgradeList = res;
      });
      this.subscriptions.push(upgrade$);
    } else if (this.type === 'adlink') {
      const adlink$ = this.adlinkService.getAll$(1).subscribe(res => {
        this.adLinkList = res;
      });
      this.subscriptions.push(adlink$);
    }

    if (this.event[this.type + 's'] && this.event[this.type + 's'].length) {
      this.event[this.type + 's'].forEach(i => {
        this.checked.list[i.id] = true;
        this.checked.selected.ids.push(i.id);
      });
      this.checked.selected.total = this.event[this.type + 's'].length;
    }
    if (this.type === 'adlink' && this.event.adlink) {
      this.checked.list[this.event.adlink.id] = true;
      this.checked.selected.ids.push(this.event.adlink.id);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    if (this.type === 'adlink') {
      const adlinkId = this.checked.selected.ids[0] ?? null;
      const subscription$ = this.eventsService
        .updateAdlink$(this.event.id, adlinkId)
        .subscribe({
          next: response => {
            this.event = response.data;
            this.onDismiss('success');
          },
          error: error => {
            console.error(error);
          },
        });
    } else {
      // Editamos la nm de event
      const u$ = this.eventsService
        .update$(this.event.id, this.type, this.checked.selected.ids)
        .subscribe({
          next: response => {
            this.event = response.data;
            this.onDismiss('success');
          },
          error: err => {
            console.log(err);
          },
        });
      this.subscriptions.push(u$);
    }
  }

  public check(id) {
    this.checked.selected = { ids: [], total: 0 };
    if (this.type === 'adlink') {
      if (this.checked.list[id]) {
        this.checked.list[id] = false;
        this.checked.selected.total = 0;
        this.checked.selected.ids = [];
      } else {
        this.checked.list = {};
        this.checked.list[id] = true;
        this.checked.selected.total = 1;
        this.checked.selected.ids = [Number(id)];
      }
    } else {
      this.checked.list[id] = !this.checked.list[id];
      let all_clicked = Object.keys(this.checked.list);
      all_clicked.forEach(id => {
        if (this.checked.list[id]) {
          this.checked.selected.total += 1;
          this.checked.selected.ids.push(Number(id));
        }
      });
    }
  }
}

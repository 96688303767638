<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>Are you sure you want to delete this Upsell?</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss('cancel')"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper">
      <div>
        <div class="input-line">
          <div>
            <span><b>Name</b></span>
          </div>
          <div>
            <p>{{ data?.name }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Description</b></span>
          </div>
          <div>
            <p>{{ data?.description }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Goal</b></span>
          </div>
          <div>
            <p>{{ data?.goal }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span><b>Min. Seats to show</b></span>
          </div>
          <div>
            <p>{{ data?.minSeatsToShow }}</p>
          </div>
        </div>
        <div class="input-line">
          <div>
            <span>
              <b>Visibility</b>
            </span>
          </div>
          <div>
            <p>{{ data?.active }}</p>
          </div>
        </div>
      </div>
      <div class="ms-4">
        <div class="title mb-3">
          This Upsell belongs to the following events:
        </div>
        <div *ngIf="data?.events.length" class="input-check-lines-wrapper">
          <div class="input-check-line" *ngFor="let item of data.events">
            <i class="ti ti-check me-3 ms-3"></i
            ><span
              >{{ item.description }} <br />
              {{ item.date | date }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
      <button class="default-1" (click)="onConfirm(data.id)">
        Permanently delete
      </button>
    </div>
  </div>
</div>

<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>
        <span class="text-capitalize">{{ action }}</span> User
        <span *ngIf="user && user.id"> - Id {{ user.id }}</span>
      </p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss('cancel')"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper d-flex">
      <div class="mt-4">
        <div class="input-line">
          <div>
            <span><b>Username</b></span>
          </div>
          <div
            *ngIf="
              action === actionsEnum.edit || action === actionsEnum.create
            ">
            <input
              type="text"
              [(ngModel)]="user.username"
              [class.invalid-input]="hashValidator.username" />
            <span class="small error-color" *ngIf="hashValidator.username"
              >This entry is mandatory</span
            >
          </div>
          <div *ngIf="action === actionsEnum.delete">
            {{ user.username }}
          </div>
        </div>

        <div
          class="input-line"
          *ngIf="action === actionsEnum.create || action === actionsEnum.edit">
          <div>
            <span class="password-title-container">
              <div class="password-help-container">
                <div>Must contain:</div>
                <div>- Minimum eight characters</div>
                <div>- At least one uppercase letter</div>
                <div>- At least one lowercase letter</div>
                <div>- At least one number</div>
                <div>- At least one special character ($!%*?&)</div>
              </div>
              <b>Password</b> <i class="ti ti-help-alt password-help-icon"></i>
            </span>
          </div>
          <div>
            <label for="user-password" class="password-container">
              <input
                id="user-password"
                [type]="passwordInputType"
                [(ngModel)]="user.password"
                [class.invalid-input]="hashValidator.password" />

              <i
                class="ti ti-eye"
                *ngIf="passwordInputType === 'password'"
                (click)="togglePasswordInputType()"></i>
              <i
                class="ti ti-power-off"
                *ngIf="passwordInputType === 'text'"
                (click)="togglePasswordInputType()"></i>
            </label>
            <span class="small error-color" *ngIf="hashValidator.password"
              >This entry is invalid</span
            >
          </div>
        </div>

        <div class="input-line" *ngIf="action === actionsEnum.edit">
          <div>
            <div>
              <span><b>Confirm password</b></span>
            </div>
            <input
              id="confirm-password"
              [type]="passwordInputType"
              [(ngModel)]="confirmPassword.value"
              [class.invalid-input]="confirmPassword.hasError" />
            <span class="small error-color" *ngIf="confirmPassword.hasError"
              >This entry not match the password</span
            >
          </div>
        </div>

        <div class="input-line">
          <div>
            <span><b>Email</b></span>
          </div>
          <div
            *ngIf="
              action === actionsEnum.edit || action === actionsEnum.create
            ">
            <input
              type="text"
              [(ngModel)]="user.email"
              [class.invalid-input]="hashValidator.email" />
            <span class="small error-color" *ngIf="hashValidator.email"
              >This entry is invalid</span
            >
          </div>
          <div *ngIf="action === actionsEnum.delete">
            {{ user.email }}
          </div>
        </div>

        <div class="input-line">
          <div>
            <span><b>Type</b></span>
          </div>
          <div
            *ngIf="
              action === actionsEnum.edit || action === actionsEnum.create
            ">
            <select [(ngModel)]="user.type">
              <option [value]="value" *ngFor="let value of userTypes">
                {{ value }}
              </option>
            </select>
          </div>

          <div *ngIf="action === actionsEnum.delete">
            <select disabled>
              <option>{{ user.type }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-containers">
      <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
      <button class="default-1" (click)="onConfirm()">
        {{ action === actionsEnum.delete ? 'Permanently delete' : 'Save' }}
      </button>
    </div>
  </div>
</div>

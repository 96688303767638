import { Component, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PaginationComponent } from '../layout/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RedisManagerService } from './services/redis-manager.service';
import { RedisRecord } from './models/redis-manager.model';
import { DeleteComponent } from './modals/delete/delete.component';
import { DeleteAllComponent } from './modals/delete-all/delete-all.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-redis-manager',
  templateUrl: './redis-manager.component.html',
  styleUrl: './redis-manager.component.scss',
})
export class RedisManagerComponent implements OnInit, OnDestroy {
  public data = signal<RedisRecord[]>([]);
  public search = signal('');
  public redisRecord: RedisRecord = null;
  public selectedKeys = signal(new Set());
  public bsModalRef?: BsModalRef;
  public subscriptions: Array<Subscription>;
  public refreshing: boolean;
  public nextCursor: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private redisManagerService: RedisManagerService,
    private toastrService: ToastrService
  ) {
    this.data.set([]);
    this.selectedKeys.set(new Set());
    this.subscriptions = [];
    this.refreshing = false;
  }

  get json() {
    if (!this.redisRecord) {
      return '';
    }
    let json = null;

    try {
      json = JSON.parse(this.redisRecord?.value);
    } catch (error) {
      json = this.redisRecord?.value;
    }

    return JSON.stringify(json, undefined, 2);
  }

  ngOnInit(): void {
    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data.set(resolved.data);
    this.nextCursor = resolved.nextCursor;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  toggleSelectKey(key: string) {
    if (this.selectedKeys().has(key)) {
      this.selectedKeys().delete(key);
    } else {
      this.selectedKeys().add(key);
    }
  }

  toggleSelectAllKeys() {
    if (this.selectedKeys().size === this.data().length) {
      this.selectedKeys().clear();
    } else {
      const newSelectedKeys = new Set();
      this.data().forEach(o => newSelectedKeys.add(o.key));
      this.selectedKeys.set(newSelectedKeys);
    }
  }

  onChange(event: any) {
    this.search.set(event.target.value);
  }

  onSearch() {
    this.redisRecord = null;
    this.getAll();
  }

  getAll() {
    this.refreshing = true;
    const all$ = this.redisManagerService
      .getAll$({ search: this.search() })
      .subscribe({
        next: (res: any) => {
          this.refreshing = false;
          this.data.set(res.data);
          this.nextCursor = res.nextCursor;
          const newSelectedKeys = new Set();
          this.data().forEach(o => {
            if (this.selectedKeys().has(o.key)) {
              newSelectedKeys.add(o.key);
            }
          });
          this.selectedKeys.set(newSelectedKeys);
        },
        error: () => {
          this.refreshing = false;
          this.toastrService.error(
            'An error occurred trying to get the redis keys',
            'Error'
          );
        },
      });
    this.subscriptions.push(all$);
  }

  selectRedisRecord(redisRecord: RedisRecord) {
    if (this.redisRecord?.key === redisRecord.key) {
      this.redisRecord = null;
    } else {
      this.redisRecord = redisRecord;
    }
  }

  public getNextKeys() {
    this.refreshing = true;
    const all$ = this.redisManagerService
      .getAll$({ cursor: this.nextCursor, search: this.search() })
      .subscribe({
        next: (res: any) => {
          this.refreshing = false;
          const newKeys = [...this.data(), ...res.data];
          this.data.set(newKeys);
          this.nextCursor = res.nextCursor;
          const newSelectedKeys = new Set();
          this.data().forEach(o => {
            if (this.selectedKeys().has(o.key)) {
              newSelectedKeys.add(o.key);
            }
          });
          this.selectedKeys.set(newSelectedKeys);
        },
        error: () => {
          this.refreshing = false;
          this.toastrService.error(
            'An error occurred trying to get the redis keys',
            'Error'
          );
        },
      });
    this.subscriptions.push(all$);
  }

  public openModalDelete(key: string) {
    const initialState: object = {
      key,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell',
    };
    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
    this.refreshListOnHide();
  }

  public openModalDeleteAll() {
    const initialState: object = {
      keys: Array.from(this.selectedKeys()),
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell',
    };
    this.bsModalRef = this.modalService.show(DeleteAllComponent, modalConfig);
    this.refreshListOnHide();
  }

  private refreshListOnHide() {
    const onH$ = this.bsModalRef.onHide.subscribe(response => {
      if (this.bsModalRef.content.closeReason == 'success') {
        this.getAll();
      }
    });
    this.subscriptions.push(onH$);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { RedisRecord } from '../../models/redis-manager.model';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RedisManagerService } from '../../services/redis-manager.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-all',
  templateUrl: './delete-all.component.html',
  styleUrl: './delete-all.component.scss',
})
export class DeleteAllComponent implements OnInit, OnDestroy {
  public keys: string[];
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  private waitingApi: boolean;
  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private redisManagerService: RedisManagerService,
    private toastrService: ToastrService
  ) {
    this.subscriptions = [];
    this.waitingApi = false;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }
  ngOnInit(): void {
    if (this.modalOptions && this.modalOptions.initialState) {
      this.keys = this.modalOptions.initialState['keys'] as string[];
    }
  }
  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    if (this.waitingApi) return;
    this.waitingApi = true;
    this.redisManagerService.deleteKeys$(this.keys).subscribe({
      next: () => {
        this.onDismiss('success');
      },
      error: () => {
        this.toastrService.error(
          'An error occurred trying to delete all redis keys',
          'Error'
        );
      },
    });
  }
}

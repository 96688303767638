<div class="row">
  <div class="col">
    <figure class="highcharts-figure">
      <div id="highcharts-container"></div>
    </figure>
  </div>

  <div
    class="col"
    *ngIf="eventList.length && resolved.length; else notransactions">
    <div class="d-flex">
      <div id="analytics_wrapper" class="event_finder">
        <div>
          <ng-multiselect-dropdown
            [placeholder]="'Select Event'"
            [settings]="dropdownSettings"
            [(ngModel)]="selectedItems"
            [data]="eventList"
            (onSelect)="ondropdownSelect($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div>
        <button type="button" class="default-1" (click)="search()">
          <i class="ti ti-search me-2"></i> <span class="me-3">Search</span>
        </button>
      </div>
    </div>
    <div class="last_numbers_wrapper row">
      <div class="last_numbers col-6" *ngFor="let item of summaryNumbers">
        <div>
          <div>
            <b class="me-1"
              ><span class="last_numbers_desc"> {{ item.description }}</span></b
            >
          </div>
          <div>
            <small>{{ item.code }}</small
            ><small class="ms-1">{{ item.date | date }}</small>
          </div>
          <div>
            <span
              >Transactions Completed : {{ item.ok_event_transactions }}</span
            >
          </div>
          <div>
            <span
              >Transactions Pending Hold :
              {{ item.ph_event_transactions }}</span
            >
          </div>
          <div>
            <span
              >Balance: <b>$ {{ item.ok_balance_event_transactions }}</b></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notransactions> Empty data </ng-template>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private onlyNumbers = new RegExp('^[0-9]+$');

  constructor() {}

  /**
   * Check using RegExp if string has only numbers
   * @param {string} value - item to test
   * @returns {boolean} - true if only has numbers, otherwise false
   */
  public onlyHasNumbers(value): boolean {
    return this.onlyNumbers.test(value);
  }

  /**
   * Checks if a JavaScript value is empty
   * @example
   *    isEmpty(null); // true
   *    isEmpty(undefined); // true
   *    isEmpty(''); // true
   *    isEmpty([]); // true
   *    isEmpty({}); // true
   * @param {any} value - item to test
   * @returns {boolean} true if empty, otherwise false
   */
  public isEmpty(value): boolean {
    return (
      value === null || // check for null
      value === undefined || // check for undefined
      value === '' || // check for empty string
      (Array.isArray(value) && value.length === 0) || // check for empty array
      (typeof value === 'object' && Object.keys(value).length === 0) // check for empty object
    );
  }

  public getCurrentDayFormatted() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // +1 because getMonth() returns 0-11
    const year = now.getFullYear();
    return `${day}_${month}_${year}`;
  }

  public isValidEmail(email: string): boolean {
    return Boolean(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
      )
    );
  }

  public isValidPassword(password: string): boolean {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    return Boolean(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        String(password)
      )
    );
  }

  public isValidUrl(url: string) {
    return Boolean(
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        String(url)
      )
    );
  }
}

export const USER_TYPES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  MANAGER: 'manager',
  REP: 'sales_person',
} as const;

export type UserTypes = (typeof USER_TYPES)[keyof typeof USER_TYPES];

export interface User {
  id: number;
  username: string;
  password?: string;
  email: string;
  type: UserTypes;
  isSuperadmin: boolean;
  pvPatronId: string | null;
  createdAt?: string; // 2024-09-02T15:49:27.000+00:00
  updatedAt?: string; // 2024-09-02T15:49:27.000+00:00
}

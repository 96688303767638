<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>{{ action === 'edit' ? 'Edit' : 'Preview' }} Confirmation Email</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss('cancel')"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper mt-4" [class.preview]="action === 'preview'">
      <div *ngIf="action === 'edit'">
        <div class="input-line mb-3">
          <div>
            <span><b>Logo URL</b></span>
          </div>
          <div class="image-input-container">
            <div>
              <input
                type="text"
                [(ngModel)]="data.emailData['logoUrl']"
                [class.invalid-input]="hashValidator.logoUrl" />
              <span class="small error-color" *ngIf="hashValidator.logoUrl"
                >This entry must be a url</span
              >
            </div>

            <div
              class="image-preview-icon"
              (mouseenter)="showPreviewImage('logoUrl')"
              (mouseleave)="hidePreviewImage()">
              <i class="ti ti-camera"></i>
            </div>
          </div>
        </div>

        <div class="input-line mb-3">
          <div>
            <span><b>Header Banner URL</b></span>
          </div>
          <div class="image-input-container">
            <div>
              <input
                type="text"
                [(ngModel)]="data.emailData['headerUrl']"
                [class.invalid-input]="hashValidator.headerUrl" />
              <span class="small error-color" *ngIf="hashValidator.headerUrl"
                >This entry must be a url</span
              >
            </div>

            <div
              class="image-preview-icon"
              (mouseenter)="showPreviewImage('headerUrl')"
              (mouseleave)="hidePreviewImage()">
              <i class="ti ti-camera"></i>
            </div>
          </div>
        </div>

        <div class="input-line mb-3">
          <div>
            <span><b>Footer Banner URL</b></span>
          </div>
          <div class="image-input-container">
            <div>
              <input
                type="text"
                [(ngModel)]="data.emailData['footerUrl']"
                [class.invalid-input]="hashValidator.footerUrl" />
              <span class="small error-color" *ngIf="hashValidator.footerUrl"
                >This entry must be a url</span
              >
            </div>

            <div
              class="image-preview-icon"
              (mouseenter)="showPreviewImage('footerUrl')"
              (mouseleave)="hidePreviewImage()">
              <i class="ti ti-camera"></i>
            </div>
          </div>
        </div>

        <div class="input-line mb-3">
          <div>
            <span><b>Legal text</b></span>
          </div>
          <div>
            <textarea
              type="text"
              [(ngModel)]="data.emailData['legalText']"
              [class.invalid-input]="hashValidator.legalText"></textarea>
            <span class="small error-color" *ngIf="hashValidator.legalText"
              >This entry is mandatory. Please check for line breaks or
              spaces.</span
            >
          </div>
        </div>

        <div class="input-line mb-3">
          <div>
            <span><b>Greeting text</b></span>
          </div>
          <div>
            <textarea
              type="text"
              [(ngModel)]="data.emailData['greetingText']"></textarea>
          </div>
        </div>

        <div
          class="input-line mb-3"
          [title]="data.isActive ? 'Enabled' : 'Disabled'">
          <div>
            <span><b>Enable Template</b></span>
          </div>
          <div class="py-2">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="data.isActive" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div id="html-preview" *ngIf="action === 'preview'">
        <div [innerHTML]="templateHtml"></div>
      </div>
    </div>

    <div class="btns-containers" *ngIf="action === 'edit'">
      <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
      <button class="default-1" (click)="onConfirm()">Save</button>
    </div>

    <div class="btns-containers preview" *ngIf="action === 'preview'">
      <button class="default-1" (click)="onDismiss('cancel')">Close</button>
    </div>
  </div>

  <div class="image-preview-cotainer" *ngIf="previewImageUrl">
    <img [src]="previewImageUrl" alt="preview-image" />
  </div>
</div>

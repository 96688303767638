import { Injectable } from '@angular/core';
import { DrawerPosition, DrawerRemoteControl } from '@ng-vibe/drawer';

export interface ComponentType<T> {
  new (...args: any[]): T;
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private drawer: DrawerRemoteControl;

  constructor() {}

  public getPayload() {
    return this.drawer.payload;
  }

  public openDrawer(drawerComponent: any, payload = {}) {
    this.drawer = new DrawerRemoteControl(drawerComponent as any);

    this.drawer.options = {
      position: DrawerPosition.RIGHT,
      showOverlay: true,
      width: '80vw',
    };

    this.drawer.openDrawer(payload).subscribe(resp => {
      console.log('Response from child component:', resp);
    });
  }

  public closeDrawer() {
    this.drawer.closeDrawer();
  }
}

<div class="modal-container m-4">
  <div class="data-container">
    <div class="d-flex w-100 justify-content-end">
      <button
        type="button"
        class="btn-modal-header border-0 bg-white"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div
      class="content-wrapper mw-100 m m-0 gap-4 d-flex flex-column justify-content-center align-content-center text-center">
      <p>
        Are you sure you want to <b>{{ action }}</b> all the selected events?
      </p>

      <div class="w-100 mb-0 d-flex justify-content-center">
        <button (click)="onConfirm()" type="submit" class="default-2">
          Submit
        </button>
        <button (click)="onDismiss()" class="default-2 red">Cancel</button>
      </div>
    </div>
  </div>
</div>

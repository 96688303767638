import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EditComponent } from './modals/edit/edit.component';
import { ViewComponent } from './modals/view/view.component';
import { Event, QueryParams } from './models/event.model';
import { EventsService } from './services/events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  public data: Array<Event>;
  public total_items_in_db: number;
  public items_per_page: number;
  private subscriptions: Array<Subscription>;
  private bsModalRef?: BsModalRef;
  public refreshing: boolean;
  private current_page: number;
  public queryParams: QueryParams;
  private dataBuffer: any;
  public activeSearch: boolean;

  constructor(
    private eventsService: EventsService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.subscriptions = [];
    this.refreshing = false;
    this.queryParams = { future: true };
  }

  ngOnInit(): void {
    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.dataBuffer = resolved;
    console.log(this.data);
    this.total_items_in_db = resolved.meta.total;
    this.items_per_page = resolved.meta.per_page;
    this.current_page = 1;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      if (params['page']) {
        this.current_page = params['page'];
        let api = this.activatedRoute.snapshot.data['api'];
        if (this.current_page && api.meta.current_page !== this.current_page) {
          this.getall();
        }
      }
    });
    this.subscriptions.push(queryparams$);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public openModalView(eventid: number) {
    const initialState: object = {
      eventid: eventid,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'view-event modal-lg',
    };
    this.bsModalRef = this.modalService.show(ViewComponent, modalConfig);
    const modalSubscription = this.bsModalRef.onHide.subscribe(
      (action: any) => {
        let updatedValue = this.bsModalRef.content.result as Event;
        let eventIndex = this.data.findIndex(
          event => event.pvEventId === updatedValue.pvEventId
        );
        this.data[eventIndex] = updatedValue;
        modalSubscription.unsubscribe();
      }
    );
  }
  public openModalEdit(event: Event, type: string) {
    const initialState: object = {
      event: event,
      type: type,
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-event modal-lg',
    };
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide();
  }
  private refreshListOnHide() {
    const onH$ = this.bsModalRef.onHide.subscribe(response => {
      if (this.bsModalRef.content.closeReason == 'success') {
        const a$ = this.eventsService
          .getAll$(this.current_page, this.queryParams)
          .subscribe(res => {
            this.data = res.data;
            this.total_items_in_db = res.meta.total;
            this.items_per_page = res.meta.per_page;
          });
        this.subscriptions.push(a$);
      }
    });
    this.subscriptions.push(onH$);
  }
  public refreshEvents() {
    this.refreshing = true;
    const r$ = this.eventsService.refresh$().subscribe(res => {
      this.getall();
    });
    this.subscriptions.push(r$);
  }
  public toggleFuture() {
    this.queryParams.future = !this.queryParams.future;

    this.refreshing = true;
    this.getall();
  }

  public searchEvent(value: string, form: NgForm): void {
    this.activeSearch = true;
    this.refreshing = true;
    const searchEvent$ = this.eventsService.searchEvent$(value).subscribe(
      res => {
        this.refreshing = false;
        this.data = res.data;
        this.total_items_in_db = res.meta.total;
        this.items_per_page = res.meta.per_page;
      },
      error => {
        //
        if (error.code === 'E_SEARCH_EVENT_BAD_VALUE') {
          this.refreshing = false;
          console.error(error.message);
        }
      }
    );
    this.subscriptions.push(searchEvent$);
  }

  public restoreTable(input: HTMLInputElement): void {
    this.activeSearch = false;
    input.value = '';
    this.getall();
  }

  private getall() {
    const all$ = this.eventsService
      .getAll$(this.current_page, this.queryParams)
      .subscribe(res => {
        this.refreshing = false;
        this.data = res.data;
        this.dataBuffer = res.data;
        this.total_items_in_db = res.meta.total;
        this.items_per_page = res.meta.per_page;
      });
    this.subscriptions.push(all$);
  }
}

<div class="header-wrapper">
  <div class="header-wrapper-col">
    <img [src]="configurationService.configuration['logo']" alt="club logo" />
    <h1>
      {{ configurationService.configuration['appName'] + ' Group Sales' }}
    </h1>
  </div>
  <div class="header-wrapper-col" (click)="menu.open = !menu.open">
    <span class="username-value">{{ username }}</span>
    <div class="user-icon-wrapper">
      <i class="ti ti-user"></i>
    </div>
  </div>
</div>
<div [hidden]="!menu.open">
  <div class="header-menu-user">
    <button type="button" (click)="logout()">Log Out</button>
  </div>
</div>

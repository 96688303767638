import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize, interval, Subscription } from 'rxjs';
import { AuthService } from '../../private/services/auth.service';
import { ConfigurationService } from '../../private/services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    '../../private/layout/header/header.component.scss',
  ],
})
export class LoginComponent implements OnDestroy, OnInit {
  public submitResultTypes = {
    error: 'error',
    success: 'success',
  } as const;
  public submitResult:
    | (typeof this.submitResultTypes)[keyof typeof this.submitResultTypes]
    | null = null;
  private defaultErrorMessage =
    'Something went wrong please try again or contact support@3ddigitalvenue.com';
  public submitMessage = '';
  public viewTypes = {
    login: 'login',
    resetPassword: 'reset-password',
  } as const;
  public currentView: (typeof this.viewTypes)[keyof typeof this.viewTypes] =
    this.viewTypes.login;
  public passwordInputType: 'password' | 'text' = 'password';
  public formData: ReturnType<typeof this.getInitFormData>;
  public isLoading = false;
  public resetAttempts = 0;
  public retryInSeconds = 0;
  private subscriptions: Array<Subscription>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public configurationService: ConfigurationService,
    private toastr: ToastrService
  ) {
    this.formData = this.getInitFormData();
    this.subscriptions = [];
  }

  ngOnInit() {
    const queryParamsSub = this.activatedRoute.queryParams.subscribe(param => {
      const username = param['username'];

      if (username) {
        this.formData = {
          ...this.formData,
          username,
        };

        this.submitResult = this.submitResultTypes.success;
        this.submitMessage = `Password restored for ${username}`;
      }
    });

    this.subscriptions.push(queryParamsSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public toggleView() {
    this.formData = this.getInitFormData();
    this.submitResult = null;
    this.submitMessage = '';
    this.isLoading = false;
    this.resetAttempts = 0;
    this.retryInSeconds = 0;

    this.currentView =
      this.currentView === this.viewTypes.login
        ? this.viewTypes.resetPassword
        : this.viewTypes.login;
  }

  public submit(form: NgForm) {
    if (this.currentView === this.viewTypes.login) {
      this.login();
    }

    if (this.currentView === this.viewTypes.resetPassword) {
      this.resetPassword();
    }
  }

  public login() {
    if (this.formData.username && this.formData.password) {
      const loginSub = this.authService.login(this.formData).subscribe({
        next: response => {
          this.router.navigate(['/private/dashboard']);
        },
        error: error => {
          console.log(error);
          this.submitResult = this.submitResultTypes.error;
          this.submitMessage = this.defaultErrorMessage;
        },
      });

      this.subscriptions.push(loginSub);
    }
  }

  public resetPassword() {
    if (this.formData.username) {
      this.resetAttempts += 1;
      this.isLoading = true;

      const resetPasswordSub = this.authService
        .resetPassword(this.formData.username)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: response => {
            this.submitResult = this.submitResultTypes.success;
            this.submitMessage = `We have sent an email to ${response.data.email} that contains a link to verify your email address`;
          },
          error: error => {
            console.log(error);

            this.toastr.error(error?.error?.message || 'Unknown Error');

            if (error?.error?.code === 'E_TOO_MANY_REQUESTS') {
              // errorMessage = 'Too Many Requests. Retry in 120 seconds'
              const errorMessage: string = error.error.message;
              const startString = 'Too Many Requests. Retry in ';
              const endString = ' seconds';
              const startIndex = startString.length;
              const endIndex = errorMessage.indexOf(endString);
              const seconds = errorMessage.substring(startIndex, endIndex);

              this.retryInSeconds = Number(seconds);
              this.startResendCountdown(Number(seconds));
            } else {
              this.submitResult = this.submitResultTypes.error;
              this.submitMessage = this.defaultErrorMessage;
            }
          },
        });

      this.subscriptions.push(resetPasswordSub);
    }
  }

  public togglePasswordInputType() {
    this.passwordInputType =
      this.passwordInputType === 'password' ? 'text' : 'password';
  }

  private getInitFormData() {
    return {
      username: '',
      password: '',
    };
  }

  private startResendCountdown(intervalDuration: number) {
    const intervalSub = interval(1000).subscribe((count: number) => {
      if (count >= intervalDuration) {
        intervalSub.unsubscribe();
      } else {
        this.retryInSeconds -= 1;
      }
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthUserModel, JwtAuthData } from '../models/auth-user.model';
import { User } from '../users/models/users.model';

interface LoginApiResponse {
  user: User;
  jwt: Omit<JwtAuthData, 'authType'> & {
    type: JwtAuthData['authType'];
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: AuthUserModel;
  private userLogged$: BehaviorSubject<AuthUserModel>;

  constructor(private http: HttpClient) {
    this.userLogged$ = new BehaviorSubject<AuthUserModel>(undefined);
  }

  public login(loginBody: { username: string; password: string }) {
    return this.http.post(environment.apiURl + '/bo/login', loginBody).pipe(
      tap((result: LoginApiResponse) => {
        if (result.jwt.token) {
          const authUser: AuthUserModel = {
            ...result.jwt,
            ...result.user,
            authType: result.jwt.type,
          };

          localStorage.setItem(
            environment.authStorageKey,
            JSON.stringify(authUser)
          );
        }

        this.userLogged$.next(this.user);
      })
    ) as Observable<LoginApiResponse>;
  }

  public logout() {
    const body = {};
    this.http.post<{ revoked: boolean }>(environment.apiURl + '/logout', body);

    this.userLogged$.next(undefined);
    localStorage.removeItem(environment.authStorageKey);
  }

  public getUserLogged(): Observable<AuthUserModel> {
    this.checkCurrentuser();
    return this.userLogged$.asObservable();
  }

  public checkIsSuperadmin(): boolean {
    this.checkCurrentuser();
    return this.user.username === 'superadmin';
  }

  public checkIsAdmin(): boolean {
    this.checkCurrentuser();
    return this.user.type === 'admin';
  }

  public checkCurrentuser() {
    const item = localStorage.getItem(environment.authStorageKey);

    this.user = item ? JSON.parse(item) : undefined;

    if (this.user !== undefined) {
      const check = this.checkTokenExpiration();
      if (item) {
        this.user = check == false ? JSON.parse(item) : undefined;
      }
    }

    this.userLogged$.next(this.user);
  }

  public checkTokenExpiration() {
    if (localStorage.getItem(environment.authStorageKey) === null) {
      return true;
    } else {
      const date = new Date();
      const item = localStorage.getItem(environment.authStorageKey);
      const user = JSON.parse(item);
      if (!user || date > new Date(user.expires_at)) {
        localStorage.removeItem(environment.authStorageKey);
        localStorage.removeItem('path');
        return true;
      }
      return false;
    }
  }

  public resetPassword(username: string) {
    return this.http.post<{ msg: string; data: { email: string } }>(
      environment.apiURl + '/bo/reset-password',
      { username }
    );
  }

  public restorePassword(formData: {
    username: string;
    password: string;
    token: string;
  }) {
    return this.http.post<{ msg: string }>(
      environment.apiURl + '/bo/restore-password',
      formData
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Adlink } from '../models/adlink.model';

@Injectable({
  providedIn: 'root',
})
export class AdlinkService {
  private apiRoot = '/bo/summarylink';

  constructor(private http: HttpClient) {}

  public getAll$(page?: number): Observable<any> {
    let query = '';
    if (page) {
      query = '?page=' + page.toString();
    }
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}${query}`);
  }

  public get$(id: number): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }
  public create$(
    body: Adlink,
    file?: File,
    events?: Array<number>
  ): Observable<any> {
    let formData: FormData = new FormData();
    let options = {};
    if (file) {
      formData.append('image', file, file.name);
    }
    formData.append('active', body.active.toString());
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.title) {
      formData.append('title', body.title);
    }
    if (body.link) {
      formData.append('link', body.link);
    }
    if (events && events.length) {
      events.forEach(i => {
        formData.append('events[]', i.toString());
      });
    }

    return this.http.post<any>(
      environment.apiURl + `${this.apiRoot}`,
      formData,
      options
    );
  }
  public delete$(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }

  public updateEventsBulk$(body: any) {
    return this.http.patch<any>(
      environment.apiURl + `${this.apiRoot}/events/bulk`,
      body
    );
  }

  public update$(body: Adlink, file?: File, events?: Array<number>) {
    let formData: FormData = new FormData();
    let options = {};
    console.log(file);
    if (file) {
      formData.append('image', file, file.name);
    }
    if (typeof body.active === 'boolean') {
      formData.append('active', body.active.toString());
    }
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.title) {
      formData.append('title', body.title);
    }
    if (body.link) {
      formData.append('link', body.link);
    }
    if (events && events.length) {
      events.forEach(i => {
        formData.append('events[]', i.toString());
      });
    }
    if (body.url_image == null) {
      formData.append('urlImage', '');
    } else {
      formData.append('urlImage', body.url_image);
    }
    return this.http.patch<any>(
      environment.apiURl + `${this.apiRoot}/${body.id}`,
      formData,
      options
    );
  }
}

<div class="page">
  <div class="page-content">
    <div class="header">
      <div class="header-wrapper">
        <div class="header-wrapper-col">
          <img
            [src]="configurationService.configuration['logo']"
            alt="club logo" />
          <h1>
            {{ configurationService.configuration['appName'] + ' Group Sales' }}
          </h1>
        </div>
      </div>
    </div>
    <div class="page-component">
      <h1>Reset Your Password</h1>

      <form #form="ngForm" (ngSubmit)="submit(form)">
        <div class="input-line">
          <div>
            <span class="password-title-container">
              <div class="password-help-container">
                <div>Must contain:</div>
                <div>- Minimum eight characters</div>
                <div>- At least one uppercase letter</div>
                <div>- At least one lowercase letter</div>
                <div>- At least one number</div>
                <div>- At least one special character ($!%*?&)</div>
              </div>
              Password <i class="ti ti-help-alt password-help-icon"></i>
            </span>
          </div>
          <div>
            <label for="user-password" class="password-container">
              <input
                id="user-password"
                name="password"
                [type]="passwordInputType"
                [(ngModel)]="formData.password"
                [class.invalid-input]="hashValidator.password" />
              <i
                class="ti ti-eye"
                *ngIf="passwordInputType === 'password'"
                (click)="togglePasswordInputType()"></i>
              <i
                class="ti ti-power-off"
                *ngIf="passwordInputType === 'text'"
                (click)="togglePasswordInputType()"></i>
            </label>
            <span class="small error-color" *ngIf="hashValidator.password"
              >This entry is invalid</span
            >
          </div>
        </div>

        <div class="input-line">
          <div>
            <span>Confirm Password</span>
          </div>
          <div>
            <input
              name="confirmPassword"
              [(ngModel)]="formData.confirmPassword"
              [type]="passwordInputType"
              [(ngModel)]="formData.confirmPassword"
              [class.invalid-input]="hashValidator.confirmPassword" />
            <span
              class="small error-color"
              *ngIf="hashValidator.confirmPassword"
              >This entry not match the password</span
            >
          </div>
        </div>

        <div
          class="alert"
          [class.alert-danger]="submitResult === submitResultTypes.error"
          [class.alert-success]="submitResult === submitResultTypes.success"
          role="alert"
          *ngIf="!!submitResult">
          {{ submitMessage }}
        </div>

        <button type="submit" class="default-1">RESET PASSWORD</button>
      </form>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagifyModule } from 'ngx-tagify';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { EventsComponent } from './events.component';
import { DeleteErrorComponent } from './modals/delete-error/delete-error.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { EditBulkConfirmComponent } from './modals/edit-bulk-confirm/edit-bulk-confirm.component';
import { EditBulkComponent } from './modals/edit-bulk/edit-bulk.component';
import { EditComponent } from './modals/edit/edit.component';
import { ManagePrivateSellingComponent } from './modals/manage-private-selling/manage-private-selling.component';
import { ViewComponent } from './modals/view/view.component';
@NgModule({
  declarations: [
    EventsComponent,
    ViewComponent,
    EditComponent,
    DeleteComponent,
    DeleteErrorComponent,
    EditBulkComponent,
    EditBulkConfirmComponent,
    ManagePrivateSellingComponent,
  ],
  imports: [
    CommonModule,
    PaginationModule,
    ModalEventSelectorModule,
    FormsModule,
    ReactiveFormsModule,
    TagifyModule,
  ],
  exports: [EventsComponent],
})
export class EventsModule {}

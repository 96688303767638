import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Upgrade } from '../models/upgrade.model';

@Injectable({
  providedIn: 'root',
})
export class UpgradeService {
  private apiRoot = '/bo/event-upgrades';

  constructor(private http: HttpClient) {}

  public getAll$(page?: number): Observable<any> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}${query}`);
  }

  public get$(id: number): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }
  public create$(
    body: Upgrade,
    file?: File,
    nm?: Array<string | number>
  ): Observable<any> {
    let formData: FormData = new FormData();
    let options = {};
    if (file) {
      formData.append('image', file, file.name);
    }

    formData.append('active', body.active.toString());
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.description) {
      formData.append('description', body.description);
    }
    if (body.buyerTypeId) {
      formData.append('buyerTypeId', body.buyerTypeId);
    }
    if (body.buyerTypeName) {
      formData.append('buyerTypeName', body.buyerTypeName);
    }
    if (body.price) {
      formData.append('price', `${body.price}`);
    }
    if (nm) {
      if (nm.length) {
        nm.forEach(i => {
          formData.append('event[]', i.toString());
        });
      } else {
        formData.append('event[]', '');
      }
    }
    return this.http.post<any>(
      environment.apiURl + `${this.apiRoot}`,
      formData,
      options
    );
  }
  public delete$(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/${id}`);
  }
  public update$(
    body: Upgrade,
    file?: File,
    nm?: Array<string | number>
  ): Observable<Upgrade> {
    let formData: FormData = new FormData();
    let options = {};
    if (file) {
      formData.append('image', file, file.name);
    }
    formData.append('active', body.active.toString());
    if (body.name) {
      formData.append('name', body.name);
    }
    if (body.description) {
      formData.append('description', body.description);
    }
    if (body.buyerTypeId) {
      formData.append('buyerTypeId', body.buyerTypeId);
    }
    if (body.buyerTypeName) {
      formData.append('buyerTypeName', body.buyerTypeName);
    }
    if (body.price) {
      formData.append('price', body.price.toString(10));
    }
    if (body.url_image == null) {
      formData.append('urlImage', '');
    } else {
      formData.append('urlImage', body.url_image);
    }
    if (nm) {
      if (nm.length) {
        nm.forEach(i => {
          formData.append('event[]', i.toString());
        });
      } else {
        formData.append('event[]', '');
      }
    }
    console.log(formData);
    return this.http.patch<Upgrade>(
      environment.apiURl + `${this.apiRoot}/${body.id}`,
      formData,
      options
    );
  }
}

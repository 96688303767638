import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventsService } from '../../events/services/events.service';
import { TransactionService } from '../services/transactions.service';

export const transactionsResolver = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const transactionsService = inject(TransactionService);
  let page = 1;
  if (route.queryParams['page']) {
    page = route.queryParams['page'];
  }
  let extraSearch = transactionsService.search;
  return transactionsService.getAll$(page, extraSearch).pipe(
    map(list => list),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

export const eventsPageLessResolver = () => {
  const router = inject(Router);
  const eventsService = inject(EventsService);
  return eventsService.getAllPageLess$().pipe(
    map(list => list),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RedisQuery } from '../models/redis-manager.model';

@Injectable({
  providedIn: 'root',
})
export class RedisManagerService {
  private apiRoot = '/bo/redis';

  constructor(private http: HttpClient) {}

  public delete$(key: string): Observable<any> {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/${key}`);
  }

  public deleteAll$(): Observable<any> {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}`);
  }

  public deleteKeys$(keys: string[]): Observable<any> {
    return this.http.delete<any>(environment.apiURl + `${this.apiRoot}/keys`, {
      body: {
        keys,
      },
    });
  }

  public get$(key: string): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}/${key}`);
  }

  public getAll$({
    cursor = '0',
    limit = 25,
    search = '',
  }: RedisQuery = {}): Observable<any> {
    return this.http.get<any>(environment.apiURl + `${this.apiRoot}`, {
      params: {
        cursor,
        limit: limit.toString(),
        search,
      },
    });
  }
}

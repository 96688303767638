import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/private/services/utils.service';
import { EmailTemplate } from '../../models/email.model';
import { EmailsService } from '../../services/emails.service';

type ModalAction = 'edit' | 'preview';

@Component({
  selector: 'app-emails-modal',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
})
export class EmailsModalComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription>;
  public data: EmailTemplate;
  public hashValidator = {
    logoUrl: false,
    headerUrl: false,
    footerUrl: false,
    legalText: false,
    greetingText: false,
  };
  public action: ModalAction;
  public templateHtml: SafeHtml;
  public closeReason: 'cancel' | 'success';
  public previewImageUrl = '';

  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private utils: UtilsService,
    private emailsService: EmailsService,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.action = this.modalOptions.initialState['action'] as ModalAction;
    const data = this.modalOptions.initialState['data'] as EmailTemplate;

    if (this.action === 'edit') {
      this.data = data;
    }

    if (this.action === 'preview' && data) {
      this.templateHtml = this.domSanitizer.bypassSecurityTrustHtml(
        data as unknown as string
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    const hasErrors = this.checkInputs(this.data);

    if (hasErrors) {
      return;
    }

    this.emailsService.update$(this.data).subscribe({
      next: response => {
        this.data = response.data;
        this.onDismiss('success');
      },
      error: error => {
        console.log(error);
        this.toastr.error(
          error?.error?.message || error?.message || 'Unknown Error'
        );
      },
    });
  }

  private checkInputs(data: EmailTemplate): boolean {
    let hasErrors = false;

    Object.keys(this.hashValidator).forEach(value => {
      this.hashValidator[value] = false;
    });

    if (
      this.utils.isEmpty(data.emailData['logoUrl']) ||
      !this.utils.isValidUrl(data.emailData['logoUrl'] as string)
    ) {
      this.hashValidator.logoUrl = true;
      hasErrors = true;
    }
    if (
      this.utils.isEmpty(data.emailData['headerUrl']) ||
      !this.utils.isValidUrl(data.emailData['headerUrl'] as string)
    ) {
      this.hashValidator.headerUrl = true;
      hasErrors = true;
    }
    if (!this.utils.isEmpty(data.emailData['footerUrl'])) {
      if (!this.utils.isValidUrl(data.emailData['footerUrl'] as string)) {
        this.hashValidator.footerUrl = true;
        hasErrors = true;
      }
    }

    const trimmedLegal = (data.emailData['legalText'] as string)
      ?.replace(/\n/g, '')
      ?.trim();
    if (!trimmedLegal) {
      data.emailData['legalText'] = '';
      this.hashValidator.legalText = true;
      hasErrors = true;
    }

    const trimmedGreeting = (data.emailData['greetingText'] as string)
      ?.replace(/\n/g, '')
      ?.trim();
    if (!trimmedGreeting) {
      data.emailData['greetingText'] = '';
    }

    return hasErrors;
  }

  public showPreviewImage(imageField: 'logoUrl' | 'headerUrl' | 'footerUrl') {
    this.previewImageUrl = this.data.emailData[imageField] as string;
  }

  public hidePreviewImage() {
    this.previewImageUrl = '';
  }
}

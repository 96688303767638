import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsellComponent } from './upsell.component';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { FormsModule } from '@angular/forms';
import { CreateComponent } from './modals/create/create.component';
import { EditComponent } from './modals/edit/edit.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';

@NgModule({
  declarations: [
    UpsellComponent,
    CreateComponent,
    EditComponent,
    DeleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule,
    ModalEventSelectorModule,
  ],
  exports: [UpsellComponent],
})
export class UpsellModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subscription } from 'rxjs';
import { UtilsService } from 'src/app/private/services/utils.service';
import { User, USER_TYPES } from '../../models/users.model';
import { UsersService } from '../../services/users.service';

export type ModalAction = 'edit' | 'delete' | 'create';

@Component({
  selector: 'app-upload-result',
  templateUrl: './upload-result.component.html',
  styleUrls: ['./upload-result.component.scss'],
})
export class UploadResultComponent implements OnInit, OnDestroy {
  public data: Array<User>;
  public validationResult: Array<Record<string, boolean>>;
  private subscriptions: Array<Subscription>;
  private closeReason: 'success' | 'cancel';
  public isUploaded = false;
  public refreshing = false;
  public hasErrors = false;

  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private usersService: UsersService,
    private utils: UtilsService,
    private toastr: ToastrService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.data = this.modalOptions.initialState['data'] as Array<User>;

    this.validationResult = this.validateData(this.data);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    if (this.isUploaded) {
      this.onDismiss('success');
      return;
    }

    this.hasErrors = false;
    this.refreshing = true;

    const uploadSub = this.usersService
      .uploadCsv$(this.data)
      .pipe(finalize(() => (this.refreshing = false)))
      .subscribe(
        res => {
          this.isUploaded = true;
          this.hasErrors = this.data.length !== res.data.length;

          const savedUsersMap = new Map(
            res.data.map(user => [user.username, user])
          );

          this.data = this.data.map(user => {
            const savedUser = savedUsersMap.get(user.username);

            if (savedUser) {
              return { ...user, ...savedUser };
            }

            return user;
          });
        },
        error => {
          this.toastr.error(error?.error?.message, `Error saving users`);
          console.error(`Error saving users`, error);
        }
      );

    this.subscriptions.push(uploadSub);
  }

  private validateData(data: Array<User>) {
    return data.map(user => {
      const username = this.utils.isEmpty(user.username);
      const password = !this.utils.isValidPassword(user.password);
      const email = !this.utils.isValidEmail(user.email);
      const type = Object.values(USER_TYPES).every(v => v !== user.type);

      if (!this.hasErrors) {
        this.hasErrors = username || password || email || type;
      }

      return {
        username,
        password,
        email,
        type,
      };
    });
  }
}

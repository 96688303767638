import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventsService } from '../../services/events.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

type ActionType = 'disable' | 'enable' | 'delete';

@Component({
  selector: 'app-edit-bulk',
  templateUrl: './edit-bulk.component.html',
  styleUrls: ['./edit-bulk.component.scss'],
})
export class EditBulkComponent {
  protected eventDescriptions: string[];
  protected form: FormGroup<{ action: FormControl<ActionType> }> =
    this.formBuilder.group({
      action: this.formBuilder.control<ActionType>('disable'),
    });

  constructor(
    private bsModalRef: BsModalRef,
    private eventsService: EventsService,
    private formBuilder: FormBuilder
  ) {}

  public onDismiss() {
    this.bsModalRef.hide();
  }

  public onConfirm() {
    const value = this.form.getRawValue();
    this.eventsService.eventBulkEditEvent.emit(value.action);
  }
}

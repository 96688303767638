import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../../events/models/event.model';
import { EventsService } from '../../events/services/events.service';
import { ApiResponse } from '../../models/api.model';
import { CheckedEvents } from './models/checkedevents.model';

@Component({
  selector: 'app-modal-event-selector',
  templateUrl: './modal-event-selector.component.html',
  styleUrls: ['./modal-event-selector.component.scss'],
})
export class ModalEventSelectorComponent implements OnInit {
  public eventList: ApiResponse<Event[]>;
  public checkedEvents: CheckedEvents;

  public subscriptions: Array<Subscription>;

  @Output() checkedevents = new EventEmitter<CheckedEvents>();
  @Input() itemEvents?: Array<Event>;
  @Input() mode?: 'single' | 'multiple';
  constructor(private eventService: EventsService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.eventList = {
      metadata: {
        total: 0,
        perPage: 0,
        currentPage: 1,
        lastPage: 1,
        firstPage: 1,
        hasMorePages: false,
      },
      data: [],
      message: 'OK',
    };
    let m = this.mode;
    if (!m || (m != 'single' && 'multiple')) m = 'multiple';

    this.checkedEvents = {
      list: {},
      selected: { total: 0, ids: [] },
      mode: m,
    };

    const s = this.eventService.getAll$(1, { future: true }).subscribe(res => {
      this.eventList = res;
    });
    this.subscriptions.push(s);
    if (this.itemEvents && this.itemEvents.length) {
      this.itemEvents.forEach(i => {
        if (i) {
          this.checkedEvents.list[i.id] = true;
          this.checkedEvents.selected.ids.push(i.id);
        }
      });
      this.checkedEvents.selected.total = this.itemEvents.length;
    }
    this.checkedevents.emit(this.checkedEvents);
  }

  public goto(page: number) {
    if (!page || page > this.eventList.metadata.lastPage) return;
    const s = this.eventService
      .getAll$(page, { future: true })
      .subscribe(res => {
        this.eventList = res;
      });
    this.subscriptions.push(s);
  }

  public check(id) {
    this.checkedEvents.selected = { ids: [], total: 0 };

    if (this.checkedEvents.mode === 'single') {
      if (this.checkedEvents.list[id]) {
        this.checkedEvents.list[id] = false;
        this.checkedEvents.selected.total = 0;
        this.checkedEvents.selected.ids = [];
      } else {
        this.checkedEvents.list = {};
        this.checkedEvents.list[id] = true;
        this.checkedEvents.selected.total = 1;
        this.checkedEvents.selected.ids = [Number(id)];
      }
    } else {
      this.checkedEvents.list[id] = !this.checkedEvents.list[id];
      let allClicked = Object.keys(this.checkedEvents.list);
      allClicked.forEach(id => {
        if (this.checkedEvents.list[id]) {
          this.checkedEvents.selected.total += 1;
          this.checkedEvents.selected.ids.push(Number(id));
        }
      });
    }

    this.checkedevents.emit(this.checkedEvents);
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpsellService } from '../services/upsell.service';

export const upsellResolver = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const upsellService = inject(UpsellService);
  let page = 1;
  if (route.queryParams['page']) {
    page = route.queryParams['page'];
  }
  return upsellService.getAll$(page).pipe(
    map(list => list),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';
import { AuthService, LoginResponseData } from '../../services/auth.service';
import { EmailTemplate } from '../models/email.model';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  private apiRoot = '/bo/emails';
  private loggedUser: LoginResponseData;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.getUserLogged().subscribe(user => {
      this.loggedUser = user;
    });
  }

  public get$(
    templateName = 'orderConfirmation'
  ): Observable<ApiResponse<EmailTemplate>> {
    return this.http.get<ApiResponse<EmailTemplate>>(
      environment.apiURl + `${this.apiRoot}/${templateName}`
    );
  }

  public update$(
    data: EmailTemplate,
    templateName = 'orderConfirmation'
  ): Observable<ApiResponse<EmailTemplate>> {
    return this.http.patch<ApiResponse<EmailTemplate>>(
      environment.apiURl + `${this.apiRoot}/${templateName}`,
      data
    );
  }

  public getPreview$(
    clientName: string,
    templateName = 'orderConfirmation'
  ): Observable<ApiResponse<{ template: string }>> {
    return this.http.get<ApiResponse<{ template: string }>>(
      environment.apiURl +
        `${this.apiRoot}/preview/${templateName}?patronName=${this.loggedUser.user.username}&clientName=${clientName}`
    );
  }

  public sendDemoEmail$(
    templateName = 'orderConfirmation'
  ): Observable<ApiResponse<{ email: string }>> {
    return this.http.post<ApiResponse<{ email: string }>>(
      environment.apiURl + `${this.apiRoot}/send/test`,
      {
        templateName,
        sendTo: this.loggedUser.user.email,
        patronName: this.loggedUser.user.username,
      }
    );
  }
}

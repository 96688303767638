import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalEventSelectorComponent } from './modal-event-selector.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModalEventSelectorComponent],
  imports: [CommonModule, FormsModule],
  exports: [ModalEventSelectorComponent],
})
export class ModalEventSelectorModule {}

<div class="modal-container">
  <div class="data-container">
    <header class="title-wrapper">
      <p>CSV data preview</p>

      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss('cancel')"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </header>

    <div *ngIf="data.length === 0" class="error-container">
      Error parsing the file. Please, note that the separator must be a
      semicolon
    </div>

    <div *ngIf="hasErrors && !isUploaded" class="error-container">
      The highlighted fields are invalid. Please, fix them and upload the file
      again
    </div>

    <div *ngIf="hasErrors && isUploaded" class="error-container">
      The highlighted rows were not saved because the username exist already
    </div>

    <main class="content-wrapper">
      <div class="default-table v2" *ngIf="data.length > 0">
        <div class="loading" *ngIf="refreshing">
          <div class="text-container">
            <div>
              <span>Saving users</span>
            </div>
            <div>
              <div class="dot-typing"></div>
            </div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th *ngIf="isUploaded">Id</th>
              <th>Username</th>
              <th>Password</th>
              <th>Email</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of data; index as i">
              <td *ngIf="isUploaded">{{ user.id }}</td>
              <td
                [class.invalid-field]="
                  validationResult[i]['username'] || (isUploaded && !user.id)
                ">
                {{ user.username }}
              </td>
              <td
                [class.invalid-field]="
                  validationResult[i]['password'] || (isUploaded && !user.id)
                ">
                {{ user.password }}
              </td>
              <td
                [class.invalid-field]="
                  validationResult[i]['email'] || (isUploaded && !user.id)
                ">
                {{ user.email }}
              </td>
              <td
                [class.invalid-field]="
                  validationResult[i]['type'] || (isUploaded && !user.id)
                "
                style="text-align: center">
                {{ user.type }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>

    <footer>
      <div
        *ngIf="data.length === 0 || hasErrors"
        class="btns-containers center-button">
        <button class="default-1" (click)="onDismiss('cancel')">Close</button>
      </div>

      <div
        *ngIf="data.length > 0 && !hasErrors"
        class="btns-containers"
        [class.center-button]="isUploaded">
        <button
          class="default-4"
          (click)="onDismiss('cancel')"
          *ngIf="!isUploaded">
          Cancel
        </button>
        <button class="default-1" (click)="onConfirm()">
          {{ isUploaded ? 'Close' : 'Save' }}
        </button>
      </div>
    </footer>
  </div>
</div>

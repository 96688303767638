import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  Transaction,
  TransactionError,
  TransactionStatusObj,
} from '../../models/transaction.model';
import { DrawerService } from '../../services/drawer.service';
import { TransactionService } from '../../services/transactions.service';
import { ErrorDetailComponent } from '../error-detail/error-detail.component';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription>;
  public data: Transaction;
  public errors: Array<TransactionError>;
  public transactionStatus: TransactionStatusObj;
  public isErrorLogOpen = false;

  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private drawerService: DrawerService,
    private transactionService: TransactionService,
    private toastr: ToastrService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    if (this.modalOptions && this.modalOptions.initialState) {
      this.data = this.modalOptions.initialState['item'] as Transaction;
      this.transactionStatus = this.modalOptions.initialState[
        'transactionStatus'
      ] as TransactionStatusObj;
      if (typeof this.data.seats === 'string') {
        let seats = this.data.seats.split(';').map(s => {
          return s.split('S_')[1].split('-');
        });
        this.data.seats = seats;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public onDismiss() {
    this.bsModalRef.hide();
  }

  public onConfirm() {
    this.bsModalRef.hide();
  }

  openDrawer(payload = {}) {
    this.drawerService.openDrawer(ErrorDetailComponent, payload);
  }

  toggleErrorLog() {
    this.isErrorLogOpen = !this.isErrorLogOpen;

    if (this.isErrorLogOpen) {
      const getErrorsSub = this.transactionService
        .getErrors$(this.data.id)
        .subscribe({
          next: (result): void => {
            this.errors = result.data;
          },
          error: (error: Error): void => {
            console.log('Error finding transaction errors', error);
            this.toastr.error('Error finding transaction errors');
          },
        });

      this.subscriptions.push(getErrorsSub);
    } else {
      this.transactionService.emit({
        id: 'ERROR_LOG_CLOSED',
        transactionId: this.data.id,
      });
    }
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toastr.success(null, 'Copied to clipboard', { timeOut: 1000 });
  }
}

import { Component } from '@angular/core';
import { SidebarStatus } from './models/sidebar.model';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent {
  public sidebarStatus: SidebarStatus = {
    open: false,
  };

  public sidebarStatusEvent($status: SidebarStatus) {
    console.log($status);
  }
}

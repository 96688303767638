<div class="modal-container h-100">
  <div class="data-container">
    <div class="title-wrapper d-flex align-content-center">
      <p>Edit all selected events</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div
      class="content-wrapper gap-5 d-flex flex-column justify-content-center align-items-center align-content-center text-center my-4">
      <div
        class="bulk-edit-title d-flex justify-content-center align-items-center">
        <div>
          Which action do you want to execute for all the selected items?
        </div>
      </div>
      <div
        class="d-flex mb-0 gap-5 flex-column justify-content-around align-content-center align-items-center">
        <form [formGroup]="form">
          <select
            name="action"
            id="action"
            class="d-flex flex-column"
            [formControlName]="'action'">
            <option [value]="'disable'">Disable all</option>
            <option [value]="'enable'">Enable all</option>
            <option [value]="'delete'">Delete all</option>
          </select>
        </form>
        <div
          class="bulk-edit-events-display d-flex flex-column py-3 gap-1 justify-content-start overflow-y-auto border-dark-subtle border-2 border rounded-1">
          @for (description of eventDescriptions; track description) {
            <div>{{ description }}</div>
          }
        </div>
      </div>
      <div class="w-100 mb-0 d-flex justify-content-center">
        <button (click)="onConfirm()" type="submit" class="default-2">
          Submit
        </button>
        <button (click)="onDismiss()" class="default-2 red">Cancel</button>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiPaginationResponse } from '../../models/api.model';
import { User } from '../models/users.model';

export type ListUsersResponse = ApiPaginationResponse<User>;

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiRoot = '/bo/users';

  constructor(private http: HttpClient) {}

  public getAll$(page?: number): Observable<ListUsersResponse> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }

    return new Observable(observer => {
      this.http
        .get<ListUsersResponse>(environment.apiURl + `${this.apiRoot}${query}`)
        .subscribe(res => {
          observer.next({
            data: res.data.filter(u => !u.is_superadmin),
            meta: res.meta,
          });
          observer.complete();
        });
    });
  }

  public get$(id: number): Observable<ListUsersResponse> {
    return new Observable(observer => {
      this.http
        .get<ListUsersResponse>(environment.apiURl + `${this.apiRoot}/${id}`)
        .subscribe(res => {
          observer.next({
            data: res.data.filter(u => !u.is_superadmin),
            meta: res.meta,
          });
          observer.complete();
        });
    });
  }

  public searchUser$(username: string): Observable<ListUsersResponse> {
    return new Observable(observer => {
      this.http
        .get<ListUsersResponse>(environment.apiURl + this.apiRoot, {
          params: new HttpParams().set('search', username),
        })
        .subscribe(res => {
          observer.next({
            data: res.data.filter(u => !u.is_superadmin),
            meta: res.meta,
          });
          observer.complete();
        });
    });
  }

  public update$(user: Partial<User>): Observable<User> {
    const formData = new FormData();

    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    return this.http.patch<User>(
      environment.apiURl + `${this.apiRoot}/${user.id}`,
      formData
    );
  }

  public delete$(user: Partial<User>): Observable<User> {
    return this.http.delete<User>(
      environment.apiURl + `${this.apiRoot}/${user.id}`
    );
  }

  public create$(user: Partial<User>): Observable<User> {
    const formData = new FormData();

    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    return this.http.post<User>(environment.apiURl + this.apiRoot, formData);
  }

  public uploadCsv$(data: Array<User>): Observable<{ data: Array<User> }> {
    return this.http.post<{ data: Array<User> }>(
      environment.apiURl + this.apiRoot + '/bulk',
      { data }
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/private/models/api.model';
import { UtilsService } from 'src/app/private/services/utils.service';
import { User, USER_TYPES } from '../../models/users.model';
import { UsersService } from '../../services/users.service';

const MODAL_ACTION_ENUM = {
  edit: 'edit',
  delete: 'delete',
  create: 'create',
} as const;

export type ModalAction =
  (typeof MODAL_ACTION_ENUM)[keyof typeof MODAL_ACTION_ENUM];

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styleUrls: ['./modal-user.component.scss'],
})
export class ModalUserComponent implements OnInit, OnDestroy {
  public actionsEnum = MODAL_ACTION_ENUM;
  public user: User | null;
  public action: ModalAction;
  private subscriptions: Array<Subscription>;
  private closeReason: 'success' | 'cancel';
  public hashValidator: Record<keyof User, boolean> = {
    id: false,
    username: false,
    password: false,
    email: false,
    isSuperadmin: false,
    type: false,
    pvPatronId: false,
    createdAt: false,
    updatedAt: false,
  };
  public userTypes = Object.values(USER_TYPES).sort();
  public passwordInputType: 'password' | 'text' = 'password';
  public confirmPassword = { value: '', hasError: false };

  constructor(
    private bsModalRef: BsModalRef,
    public modalOptions: ModalOptions,
    private usersService: UsersService,
    private utils: UtilsService,
    private toastr: ToastrService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const defaultUser: User = {
      id: 0,
      username: '',
      password: '',
      email: '',
      type: USER_TYPES.ADMIN,
      isSuperadmin: false,
      pvPatronId: '',
      createdAt: '',
      updatedAt: '',
    };
    this.action = this.modalOptions.initialState['action'] as ModalAction;
    this.user = (this.modalOptions.initialState['user'] as User) || defaultUser;

    if (this.action === MODAL_ACTION_ENUM.edit) {
      this.user.password = '';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public togglePasswordInputType() {
    this.passwordInputType =
      this.passwordInputType === 'password' ? 'text' : 'password';
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    const hasErrors = this.validateInputData();

    if (hasErrors) {
      return;
    }

    const saveOptions: Record<
      ModalAction,
      () => Observable<ApiResponse<Pick<User, 'id'>>>
    > = {
      edit: () => this.usersService.update$(this.user),
      delete: () => this.usersService.delete$(this.user),
      create: () => this.usersService.create$(this.user),
    };

    const saveFunction = saveOptions[this.action];

    const saveSub = saveFunction().subscribe({
      next: () => {
        this.onDismiss('success');
      },
      error: error => {
        this.toastr.error(
          error?.error?.message,
          `Error on ${this.action} user`
        );
        console.log(`Error on ${this.action} user`, error);
      },
    });

    this.subscriptions.push(saveSub);
  }

  private validateInputData(): boolean {
    let hasErrors = false;
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach(value => {
      this.hashValidator[value] = false;
    });

    if (this.utils.isEmpty(this.user.username)) {
      this.hashValidator.username = true;
      hasErrors = true;
    }

    if (this.action === MODAL_ACTION_ENUM.create) {
      if (!this.utils.isValidPassword(this.user.password)) {
        this.hashValidator.password = true;
        hasErrors = true;
      }
    }

    if (this.action === MODAL_ACTION_ENUM.edit && this.user.password) {
      if (!this.utils.isValidPassword(this.user.password)) {
        this.hashValidator.password = true;
        hasErrors = true;
      }

      if (this.user.password !== this.confirmPassword.value) {
        this.confirmPassword.hasError = true;
        hasErrors = true;
      }
    }
    if (!this.utils.isValidEmail(this.user.email)) {
      this.hashValidator.email = true;
      hasErrors = true;
    }

    const isInvalidUserType = Object.values(USER_TYPES).every(
      v => v !== this.user.type
    );
    if (isInvalidUserType) {
      this.hashValidator.type = true;
      hasErrors = true;
    }

    return hasErrors;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedisManagerComponent } from './redis-manager.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { DeleteAllComponent } from './modals/delete-all/delete-all.component';

@NgModule({
  declarations: [DeleteComponent, DeleteAllComponent, RedisManagerComponent],
  exports: [RedisManagerComponent],
  imports: [CommonModule],
})
export class RedisManagerModule {}

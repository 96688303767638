import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api.model';
import { UtilsService } from '../../services/utils.service';
import {
  SearchQueryParams,
  Transaction,
  TransactionError,
} from '../models/transaction.model';

type TransactionEvent =
  | {
      id: 'ERROR_LOG_OPENED';
      transactionId: number;
    }
  | {
      id: 'ERROR_LOG_CLOSED';
      transactionId: number;
    };

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private apiRoot = '/bo/transactions';
  public search: SearchQueryParams;
  public TransactionEvents$ = new Subject<TransactionEvent>();

  constructor(
    private http: HttpClient,
    private fileSaverService: FileSaverService,
    private utilsService: UtilsService
  ) {}

  public getAll$(
    page?: number,
    searchParams?: SearchQueryParams
  ): Observable<ApiResponse<Transaction[]>> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }
    if (searchParams) {
      if (searchParams.params.status) {
        query += '&filters[status][$eq]=' + searchParams.params.status;
      }
      if (searchParams.params.accountId) {
        query += '&filters[patron_id][$eq]=' + searchParams.params.accountId;
      }
      if (searchParams.params.dateFrom) {
        query += '&filters[createdAt][$gte]=' + searchParams.params.dateFrom;
      }
      if (searchParams.params.dateTo) {
        query += '&filters[createdAt][$lte]=' + searchParams.params.dateTo;
      }
      if (searchParams.params.event) {
        query += '&eventCode=' + searchParams.params.event;
      }
    }

    return this.http.get<ApiResponse<Transaction[]>>(
      environment.apiURl + `${this.apiRoot}${query}`
    );
  }

  public get$(id?: number): Observable<ApiResponse<Transaction>> {
    return this.http.get<ApiResponse<Transaction>>(
      environment.apiURl + `${this.apiRoot}/${id}`
    );
  }

  public emit(event: TransactionEvent) {
    this.TransactionEvents$.next(event);
  }

  public getErrors$(id: number): Observable<ApiResponse<TransactionError[]>> {
    return this.http
      .get<
        ApiResponse<TransactionError[]>
      >(environment.apiURl + `${this.apiRoot}/errors/${id}`)
      .pipe(
        finalize(() =>
          this.TransactionEvents$.next({
            id: 'ERROR_LOG_OPENED',
            transactionId: id,
          })
        )
      );
  }

  public exportAsCsv(searchParams?: SearchQueryParams): Observable<string> {
    let query = '';
    if (searchParams) {
      if (searchParams.params.status)
        query += '&filters[status][$eq]=' + searchParams.params.status;
      if (searchParams.params.accountId)
        query += '&filters[patron_id][$eq]=' + searchParams.params.accountId;
      if (searchParams.params.dateFrom)
        query += '&filters[createdAt][$gte]=' + searchParams.params.dateFrom;
      if (searchParams.params.dateTo)
        query += '&filters[createdAt][$lte]=' + searchParams.params.dateTo;
      if (searchParams.params.event)
        query += '&eventCode=' + searchParams.params.event;
    }

    return this.http.get(
      environment.apiURl + `${this.apiRoot}/exports/csv?${query}`,
      { responseType: 'text' }
    );
  }

  public downloadCsv = (data: string, fileName: string) => {
    const currentDayFormatted = this.utilsService.getCurrentDayFormatted();
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    this.fileSaverService.save(
      blob,
      `${fileName}_export_${currentDayFormatted}.csv`
    );
  };
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { DashboardService } from './services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription>;
  public exitmap: boolean = false;
  public resolved: Array<{
    id: number;
    code: string;
    description: string;
    date: string;
    event_transactions: number;
    ca_event_transactions: number;
    ok_event_transactions: number;
    ph_event_transactions: number;
    ok_balance_event_transactions: number;
    ph_balance_event_transactions: number;
  }> = [];

  //ng multiselect
  public eventList: Array<{
    id: number;
    description: string;
  }> = [];

  public dropdownSettings: any = {
    singleSelection: false,
    idField: 'code',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  public selectedItems = [];

  public summaryNumbers = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.resolved = this.activatedRoute.snapshot.data['api'];

    if (!this.resolved.length) return;
    // Summary right side
    let allevents = this.activatedRoute.snapshot.data['events'];
    this.summaryNumbers = [
      {
        code: this.resolved[0].code,
        description: this.resolved[0].description,
        date: this.resolved[0].date,
        ok_event_transactions: this.resolved[0].ok_event_transactions,
        ph_event_transactions: this.resolved[0].ph_event_transactions,
        ok_balance_event_transactions:
          this.resolved[0].ok_balance_event_transactions,
      },
    ];
    //ng multiselect: Create list
    this.eventList = allevents.data.map(i => {
      return { code: i.code, description: i.description + ' / ' + i.code };
    });
    this.selectedItems.push({
      code: this.resolved[0].code,
      description: this.resolved[0].description,
    });
    //Chart
    let categories = [];
    let ok_transactions = [];
    let ph_transactions = [];
    let average_transactions = [];
    let totals_ok = 0;

    this.resolved.forEach(e => {
      categories.push(e.description);
      ph_transactions.push(e.ph_event_transactions);
      ok_transactions.push(e.ok_event_transactions);
      let average = (e.ph_event_transactions + e.ok_event_transactions) / 3;
      average_transactions.push(average);
      if (e.ok_event_transactions) {
        totals_ok += e.ok_event_transactions;
      }
    });

    const color_ok = '#4D8BDB';
    const color_ph = '#E2E2E2';
    const average_color = '#272E40';

    Highcharts.chart('highcharts-container', {
      chart: {
        renderTo: 'highcharts-container',
      },
      title: {
        text: 'Transactions of the last ' + this.resolved.length + ' events',
        align: 'left',
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        title: {
          text: 'Transactions',
        },
      },
      tooltip: {
        valueSuffix: ' transactions',
      },
      series: [
        {
          type: 'column',
          name: 'Confirmed',
          data: ok_transactions,
          color: color_ok,
        },
        {
          type: 'column',
          name: 'Pending Hold',
          data: ph_transactions,
          color: color_ph,
        },
        {
          type: 'spline',
          name: 'Average',
          data: average_transactions,
          color: average_color,
          marker: {
            lineWidth: 2,
            lineColor: average_color,
            fillColor: 'white',
          },
        },
      ],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public ondropdownSelect(item) {}

  public search() {
    if (!this.selectedItems.length) return;
    this.summaryNumbers = [];
    let codes = [];
    this.selectedItems.forEach(i => {
      codes.push(i.code);
    });
    const o = this.dashboardService
      .getAnalyticsEventsTransactionsByCodes$(codes)
      .subscribe(res => {
        res.forEach(i => {
          this.summaryNumbers.push({
            code: i.code,
            description: i.description,
            date: i.date,
            ok_event_transactions: i.ok_event_transactions,
            ph_event_transactions: i.ph_event_transactions,
            ok_balance_event_transactions: i.ok_balance_event_transactions,
          });
        });
      });
    this.subscriptions.push(o);
  }
}

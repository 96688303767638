<div class="content-header mb-5">
  <div>
    <h1>Redis Manager</h1>
  </div>
</div>
<div class="d-flex flex-wrap justify-content-around gap-5">
  <div>
    <div class="d-flex gap-4">
      <div class="d-flex flex-row align-items-baseline">
        <div class="me-2">
          <span>Search redis key:</span>
        </div>
        <div class="d-flex flex-row" (keyup.enter)="onSearch()">
          <div class="d-flex me-2">
            <input
              type="text"
              name="key"
              [value]="search()"
              (change)="onChange($event)"
              placeholder="Search" />
          </div>
          <div class="d-flex">
            <button class="default-2 search-button me-1" (click)="onSearch()">
              <i class="ti ti-search"></i> Search
            </button>
          </div>
        </div>
      </div>
      @if (selectedKeys().size) {
        <div>
          <button
            type="button"
            class="default-2 red"
            (click)="openModalDeleteAll()">
            Delete selected keys <i class="ti ti-trash"></i>
          </button>
        </div>
      }
    </div>
    <div class="default-table v2 rounded">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                [checked]="selectedKeys().size === data().length"
                (click)="toggleSelectAllKeys()" />
            </th>
            <th>Key</th>
            <th>Actions</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of data()">
            <td>
              <input
                type="checkbox"
                [checked]="selectedKeys().has(i.key)"
                (click)="toggleSelectKey(i.key)" />
            </td>
            <td>
              <b>{{ i.key }}</b>
            </td>
            <td>
              <button
                class="default-2 red mx-auto"
                (click)="openModalDelete(i.key)">
                Delete
              </button>
            </td>
            <td>
              <button class="eye-button mx-auto" (click)="selectRedisRecord(i)">
                <i class="ti ti-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      @if (nextCursor) {
        <div class="load-button">
          <button type="button" class="default-1" (click)="getNextKeys()">
            Load more keys <i class="ti ti-plus"></i>
          </button>
        </div>
      }
    </div>
  </div>

  <div class="rounded px-4 default-table v2 redis-record-viewer">
    <h1 class="mt-4">Redis value viewer</h1>
    @if (redisRecord) {
      <div class="pre">
        <pre>{{ json }}</pre>
      </div>
    } @else {
      <p>No redis key selected.</p>
    }
  </div>
</div>
<div class="loading" *ngIf="refreshing">
  <div class="text-container">
    <div>
      <span>Loading Redis Keys</span>
    </div>
    <div>
      <div class="dot-typing"></div>
    </div>
  </div>
</div>

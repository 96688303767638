import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../../events/models/event.model';
import { EventsService } from '../../events/services/events.service';
import { CheckedEvents } from './models/checkedevents.model';

@Component({
  selector: 'app-modal-event-selector',
  templateUrl: './modal-event-selector.component.html',
  styleUrls: ['./modal-event-selector.component.scss'],
})
export class ModalEventSelectorComponent implements OnInit {
  public eventList: {
    meta: {
      total: number;
      per_page: number;
      current_page: number;
      last_page: number;
      first_page: number;
      first_page_url: string;
      last_page_url: string;
      next_page_url: string | null;
      previous_page_url: string | null;
    };
    data: Array<Event>;
  };
  public checked_events: CheckedEvents;

  public subscriptions: Array<Subscription>;

  @Output() checkedevents = new EventEmitter<CheckedEvents>();
  @Input() itemEvents?: Array<Event>;
  @Input() mode?: 'single' | 'multiple';
  constructor(private eventService: EventsService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.eventList = {
      meta: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
        first_page: 1,
        first_page_url: '/?page=1',
        last_page_url: '/?page=1',
        next_page_url: null,
        previous_page_url: null,
      },
      data: [],
    };
    let m = this.mode;
    if (!m || (m != 'single' && 'multiple')) m = 'multiple';

    this.checked_events = {
      list: {},
      selected: { total: 0, ids: [] },
      mode: m,
    };

    const s = this.eventService.getAll$(1, { future: true }).subscribe(res => {
      this.eventList = res;
    });
    this.subscriptions.push(s);
    if (this.itemEvents && this.itemEvents.length) {
      this.itemEvents.forEach(i => {
        if (i) {
          this.checked_events.list[i.id] = true;
          this.checked_events.selected.ids.push(i.id);
        }
      });
      this.checked_events.selected.total = this.itemEvents.length;
    }
    this.checkedevents.emit(this.checked_events);
  }

  public goto(page: number) {
    if (!page || page > this.eventList.meta.last_page) return;
    const s = this.eventService
      .getAll$(page, { future: true })
      .subscribe(res => {
        this.eventList = res;
      });
    this.subscriptions.push(s);
  }

  public check(id) {
    this.checked_events.selected = { ids: [], total: 0 };

    if (this.checked_events.mode === 'single') {
      if (this.checked_events.list[id]) {
        this.checked_events.list[id] = false;
        this.checked_events.selected.total = 0;
        this.checked_events.selected.ids = [];
      } else {
        this.checked_events.list = {};
        this.checked_events.list[id] = true;
        this.checked_events.selected.total = 1;
        this.checked_events.selected.ids = [Number(id)];
      }
    } else {
      this.checked_events.list[id] = !this.checked_events.list[id];
      let all_clicked = Object.keys(this.checked_events.list);
      all_clicked.forEach(id => {
        if (this.checked_events.list[id]) {
          this.checked_events.selected.total += 1;
          this.checked_events.selected.ids.push(Number(id));
        }
      });
    }

    this.checkedevents.emit(this.checked_events);
  }
}

<div class="content-header">
  <div>
    <h1>Upgrades Management</h1>
  </div>
  <div>
    <button type="button" class="default-1" (click)="openModalCreate()">
      Create New Upgrade <i class="ti ti-plus"></i>
    </button>
  </div>
</div>
<div class="default-table v2">
  <div class="loading" *ngIf="refreshing">
    <div class="text-container">
      <div>
        <span>Loading new events</span>
      </div>
      <div>
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Description</th>
        <th>Buyer Type ID</th>
        <th>Visibility</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of data">
        <td>
          <img [src]="i.urlImage" />
        </td>
        <td>
          <b>{{ i.name }}</b>
        </td>
        <td>{{ i.description }}</td>
        <td>{{ i.buyerTypeId }}</td>
        <td class="visibility-wrapper">
          <div>
            <div *ngIf="i.active; else offvisibility">
              <button class="default-2" (click)="toggleActive(i)">
                <i class="ti ti-eye"></i>On
              </button>
            </div>
            <ng-template #offvisibility>
              <div>
                <button class="default-2 off" (click)="toggleActive(i)">
                  <i class="ti ti-eye"></i>Off
                </button>
              </div>
            </ng-template>
            <div>
              <button class="default-2 red" (click)="openModalDelete(i.id)">
                Delete
              </button>
            </div>
          </div>
        </td>
        <td>
          <button class="default-3" (click)="openModalEdit(i)">
            <i class="ti ti-pencil"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="legend">
    Showing {{ data.length }} of {{ totalItemsInDb }} total Upgrades
  </p>
</div>
<app-pagination
  [currentPage]="currentPage"
  [totalItemsInDb]="totalItemsInDb"
  [itemsPerPage]="itemsPerPage"
  #pagination></app-pagination>

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpBearerInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headerName = '';
    const rawUser = localStorage.getItem(environment.authStorageKey);

    if (rawUser !== null) {
      const user = JSON.parse(rawUser);

      if (user.auth.token !== null && !req.headers.has(headerName)) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${user.auth.token}`,
          },
        });
      }
    }

    return next.handle(req);
  }
}

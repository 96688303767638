import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subscription } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';
import { EmailsModalComponent } from './modals/emails/emails.component';
import { EmailTemplate } from './models/email.model';
import { EmailsService } from './services/emails.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
})
export class EmailsComponent implements OnInit, OnDestroy {
  public data: EmailTemplate;
  private subscriptions: Array<Subscription>;
  private bsModalRef?: BsModalRef;
  public isLoading = false;

  constructor(
    private emailsService: EmailsService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.data = this.activatedRoute.snapshot.data[
      'emailTemplate'
    ] as EmailTemplate;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public openModalPreview() {
    this.isLoading = true;
    const clientName = this.configurationService.configuration.clientName;

    const previewSub = this.emailsService
      .getPreview$(clientName)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          const modalConfig: ModalOptions = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            initialState: { action: 'preview', data: res.data.template },
            class: 'preview-modal',
          };
          this.bsModalRef = this.modalService.show(
            EmailsModalComponent,
            modalConfig
          );
        },
        error: error => {
          console.log(error);
          this.toastr.error(
            error?.error?.message || error?.message || 'Error loading preview'
          );
        },
      });

    this.subscriptions.push(previewSub);
  }

  public openModalEdit() {
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: { action: 'edit', data: this.data },
      class: 'edit-modal',
    };
    this.bsModalRef = this.modalService.show(EmailsModalComponent, modalConfig);

    const onH$ = this.bsModalRef.onHide.subscribe(() => {
      if (this.bsModalRef.content.closeReason == 'success') {
        this.data = this.bsModalRef.content.data;
      } else {
        this.emailsService.get$().subscribe(res => {
          this.data = res.data;
        });
      }
    });

    this.subscriptions.push(onH$);
  }

  public sendDemoEmail() {
    const sendEmailSub = this.emailsService.sendDemoEmail$().subscribe({
      next: res => {
        this.toastr.success(`Email sent to ${res.data.email}`);
      },
      error: error => {
        console.log(error);
        this.toastr.error(
          error?.error?.message || error?.message || 'Error loading preview'
        );
      },
    });

    this.subscriptions.push(sendEmailSub);
  }
}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CheckedEvents } from 'src/app/private/layout/modal-event-selector/models/checkedevents.model';
import { UtilsService } from 'src/app/private/services/utils.service';
import { Adlink } from '../../models/adlink.model';
import { AdlinkService } from '../../services/adlink.service';
import { Event } from '../../../events/models/event.model';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  public item: Adlink;
  public postFile: HTMLInputElement;
  public postImage: File;
  public imagePreview: any;
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  private checked_events: CheckedEvents;
  public previewlink: string;
  public hideProtocol: boolean;
  public hashValidator = {
    name: false,
    title: false,
    link: false,
  };

  constructor(
    private bsModalRef: BsModalRef,
    private modalOptions: ModalOptions,
    private adlinkService: AdlinkService,
    private utils: UtilsService
  ) {
    this.subscriptions = [];
    this.hideProtocol = false;
    this.previewlink = '';
  }

  ngOnInit(): void {
    this.item = this.modalOptions.initialState['item'] as Adlink;
    if (this.item.link) {
      let aux = this.item.link.split('https://');
      if (aux.length === 2) {
        this.hideProtocol = true;
      }
      this.previewlink = this.item.link;
    }
    this.imagePreview = null;
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm() {
    let hasError = this.checkInputs(this.item);
    if (hasError) return;
    let aux = this.item.link.split('https://');
    if (aux.length === 1) {
      this.item.link = 'https://' + aux[0];
      this.hideProtocol = true;
    }
    const update$ = this.adlinkService
      .update$(this.item, this.postImage, this.checked_events.selected.ids)
      .subscribe({
        next: (response: Adlink) => {
          console.log(response);
          this.onDismiss('success');
        },
        error: err => {
          console.log(err);
        },
      });
    this.subscriptions.push(update$);
  }

  public onFileChange(event) {
    this.postImage = event.target.files[0];
    var reader = new FileReader();
    this.imagePreview = null;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.imagePreview = reader.result;
    };
  }

  public getCheckedEvents($event) {
    this.checked_events = $event;
  }

  private setCheckedEvents(eventList: Array<Event>): void {
    this.checked_events.mode = 'multiple';
    this.checked_events.selected.total = eventList.length;
    for (let event of eventList) {
      this.checked_events.list[event.id] = true;
      this.checked_events.selected.ids.push(event.id);
    }
  }

  public linkChanged(link) {
    if (link) {
      let aux = link.split('https://');
      if (aux.length === 1) {
        this.previewlink = 'https://' + aux[0];
        this.hideProtocol = false;
      } else {
        this.previewlink = 'https://' + aux[1];
        this.hideProtocol = true;
      }
    }
  }

  public removeImage() {
    this.item.url_image = null;
    this.postImage = null;
    this.imagePreview = null;
  }

  private checkInputs(instance: Adlink): boolean {
    let hasErrors = false;
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach(value => {
      this.hashValidator[value] = false;
    });
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.link)) {
      this.hashValidator.link = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.title)) {
      this.hashValidator.title = true;
      hasErrors = true;
    }

    return hasErrors;
  }
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QueryParams } from '../models/event.model';
import { EventsService } from '../services/events.service';

export const eventsResolver = () => {
  const router = inject(Router);
  const eventsService = inject(EventsService);
  const queryParams: QueryParams = {
    future: true,
  };
  return eventsService.getAll$(1, queryParams).pipe(
    map(list => list),
    catchError(error => {
      router.navigate(['/dashboard']);
      return of({ list: null, error: error });
    })
  );
};

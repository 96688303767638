<div class="modal-container">
  <div class="data-container">
    <div class="title-wrapper">
      <p>Something went wrong...</p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div class="content-wrapper flex-column mt-5">
      <div class="d-flex justify-content-center">
        There was an error trying to delete event with identificator:
        {{ data.id }}
      </div>
      <div class="btns-containers preview">
        <button class="default-1" (click)="onDismiss()">Close</button>
      </div>
    </div>
  </div>
</div>

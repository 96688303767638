import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../models/api.model';
import { User } from '../models/users.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiRoot = '/bo/users';

  constructor(private http: HttpClient) {}

  public getAll$(page?: number): Observable<ApiResponse<User[]>> {
    let query = '';
    if (page) {
      query = '?page=' + page;
    }

    return new Observable(observer => {
      this.http
        .get<
          ApiResponse<User[]>
        >(environment.apiURl + `${this.apiRoot}${query}`)
        .subscribe(res => {
          observer.next({
            ...res,
            data: res.data.filter(u => !u.isSuperadmin),
          });
          observer.complete();
        });
    });
  }

  public get$(id: number): Observable<ApiResponse<User>> {
    return new Observable(observer => {
      this.http
        .get<ApiResponse<User>>(environment.apiURl + `${this.apiRoot}/${id}`)
        .subscribe(res => {
          observer.next({ ...res, data: res.data });
          observer.complete();
        });
    });
  }

  public searchUser$(username: string): Observable<ApiResponse<User[]>> {
    return new Observable(observer => {
      this.http
        .get<ApiResponse<User[]>>(environment.apiURl + this.apiRoot, {
          params: new HttpParams().set(
            'filters[username][$contains]',
            username
          ),
        })
        .subscribe(res => {
          observer.next({
            ...res,
            data: res.data.filter(u => !u.isSuperadmin),
          });
          observer.complete();
        });
    });
  }

  public update$(user: Partial<User>): Observable<ApiResponse<User>> {
    const formData = new FormData();

    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    return this.http.patch<ApiResponse<User>>(
      environment.apiURl + `${this.apiRoot}/${user.id}`,
      formData
    );
  }

  public delete$(
    user: Partial<User>
  ): Observable<ApiResponse<Pick<User, 'id'>>> {
    return this.http.delete<ApiResponse<Pick<User, 'id'>>>(
      environment.apiURl + `${this.apiRoot}/${user.id}`
    );
  }

  public create$(user: Partial<User>): Observable<ApiResponse<User>> {
    const formData = new FormData();

    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    return this.http.post<ApiResponse<User>>(
      environment.apiURl + this.apiRoot,
      formData
    );
  }

  public uploadCsv$(data: User[]): Observable<ApiResponse<User[]>> {
    return this.http.post<ApiResponse<User[]>>(
      environment.apiURl + this.apiRoot + '/bulk',
      { data }
    );
  }
}

import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CheckedEvents } from 'src/app/private/layout/modal-event-selector/models/checkedevents.model';
import { UtilsService } from 'src/app/private/services/utils.service';
import { Adlink } from '../../models/adlink.model';
import { AdlinkService } from '../../services/adlink.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnDestroy {
  public item: Adlink;
  public postImage: File;
  public imagePreview: any;
  private closeReason: 'success' | 'cancel';
  private checked_events: CheckedEvents;
  private waitingapi: boolean;
  private subscriptions: Array<Subscription>;
  public previewlink: string;
  public hideProtocol: boolean;
  public hashValidator = {
    name: false,
    title: false,
    link: false,
  };

  constructor(
    private bsModalRef: BsModalRef,
    private adlinkService: AdlinkService,
    private utils: UtilsService
  ) {
    this.item = {
      id: 0,
      active: true,
      name: '',
      title: '',
      urlImage: '',
      link: '',
      events: [],
    };
    this.imagePreview = null;
    this.waitingapi = false;
    this.hideProtocol = false;
    this.previewlink = '';
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  public onDismiss(reason: 'success' | 'cancel') {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onFileChange(event) {
    this.postImage = event.target.files[0];
    var reader = new FileReader();
    this.imagePreview = null;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.imagePreview = reader.result;
    };
  }

  public onConfirm() {
    if (this.waitingapi) return;
    this.waitingapi = true;
    if (this.checkInputs(this.item)) return;
    let aux = this.item.link.split('https://');
    if (aux.length === 1) {
      this.item.link = 'https://' + aux[0];
      this.hideProtocol = true;
    }
    this.adlinkService
      .create$(this.item, this.postImage, this.checked_events.selected.ids)
      .subscribe({
        next: response => {
          this.onDismiss('success');
        },
        error: err => {
          console.log(err);
        },
      });
  }

  public getCheckedEvents($event) {
    this.checked_events = $event;
  }

  public linkChanged(link) {
    if (link) {
      let aux = link.split('https://');
      if (aux.length === 1) {
        this.previewlink = 'https://' + aux[0];
        this.hideProtocol = false;
      } else {
        this.previewlink = 'https://' + aux[1];
        this.hideProtocol = true;
      }
    }
  }

  private checkInputs(instance: Adlink): boolean {
    let hasErrors = false;
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach(value => {
      this.hashValidator[value] = false;
    });
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.link)) {
      this.hashValidator.link = true;
      hasErrors = true;
    }
    if (this.utils.isEmpty(instance.title)) {
      this.hashValidator.title = true;
      hasErrors = true;
    }

    return hasErrors;
  }
}

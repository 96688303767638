<div class="content-header">
  <div class="d-flex align-items-center">
    <h1>Users Management</h1>
  </div>
  <div></div>
</div>
<div class="topbar">
  <div class="d-flex flex-row align-items-baseline">
    <div class="me-2">
      <span>Search User:</span>
    </div>
    <form #search="ngForm" (ngSubmit)="searchUser(searchUserInput.value)">
      <div class="d-flex flex-row">
        <div class="d-flex me-2">
          <input
            type="text"
            #searchUserInput
            name="code"
            placeholder="Search user using username" />
        </div>
        <div class="d-flex">
          <button class="default-2 search-button me-1" type="submit">
            <i class="ti ti-search"></i> Search
          </button>
          <button
            type="submit"
            class="default-1 search-button"
            *ngIf="activeSearch"
            (click)="clearSearch(searchUserInput)">
            Clear
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex space-between">
    <button type="button" class="default-1" (click)="openModalCreate()">
      Create New User <i class="ti ti-plus"></i>
    </button>
    <button type="button" class="default-1" (click)="csvFile.click()">
      Upload CSV <i class="ti ti-upload"></i>
    </button>
    <input
      type="file"
      class="d-none"
      #csvFile
      (change)="onFileChange($event)" />
  </div>
</div>
<div class="default-table v2">
  <div class="loading" *ngIf="refreshing">
    <div class="text-container">
      <div>
        <span>Loading new users</span>
      </div>
      <div>
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Username</th>
        <th>Email</th>
        <th>Type</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of data">
        <td>
          <b>{{ user.id }}</b>
        </td>
        <td>{{ user.username }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.type }}</td>
        <td>
          <div class="actions-container">
            <button class="default-2 red" (click)="openModalDelete(user)">
              Delete
            </button>

            <button
              type="button"
              class="default-3"
              (click)="openModalEdit(user)"
              title="Edit User">
              <i class="ti ti-pencil"></i>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="legend">
    Showing {{ data.length }} of {{ total_items_in_db }} total Users
  </p>
  <div *ngIf="data.length === 0" class="not-found">
    <span>No user with this username was found</span>
  </div>
</div>

<app-pagination
  [total_items_in_db]="total_items_in_db"
  [items_per_page]="items_per_page"></app-pagination>
